import { Box, Text, Stack, Input } from "@chakra-ui/react";
import { FaUpload, FaFileUpload } from "react-icons/fa";

/**
 * FileDropBox component allows the user to click on a dropbox area to trigger file upload.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be displayed inside the dropbox.
 * @param {RefObject} props.hiddenInput - The hidden input element used for file upload.
 * @param {string} props.acceptType - The accepted file type for upload.
 * @param {Function} props.onInputChange - The callback function to handle file input change.
 * @param {string} props.fileName - The name of the selected file.
 * @returns {JSX.Element} The rendered FileDropBox component.
 */
export default function FileDropBox({ children, hiddenInput, acceptType, onInputChange, fileName }) {
  /**
   * FileInput component is used to detect when the user has uploaded a new file.
   *
   * @param {Object} props - The component props.
   * @param {string} props.acceptType - The accepted file type for upload.
   * @param {RefObject} props.refParam - The reference to the hidden input element.
   * @returns {JSX.Element} The rendered FileInput component.
   */
  function FileInput({ acceptType, refParam }) {
    return (
      <Input
        type="file"
        height="100%"
        width="100%"
        position="absolute"
        opacity="0"
        aria-hidden="true"
        accept={acceptType}
        hidden
        ref={refParam}
        onChange={(e) => onInputChange(e.target.files[0])}
      />
    );
  }

  /**
   * Retrieves the file extension from a given filename.
   *
   * @param {string} filename - The name of the file.
   * @returns {string} The file extension.
   */
  function getFileExtension(filename) {
    return "." + filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      h="100%"
      w="100%"
      borderColor="gray.300"
      borderStyle="dashed"
      borderWidth="2px"
      rounded="md"
      shadow="sm"
      role="group"
      transition="all 150ms ease-in-out"
      _hover={{
        shadow: "md",
      }}
      initial="rest"
      animate="rest"
    >
      <FileInput acceptType={acceptType} refParam={(el) => (hiddenInput = el)} />
      <Box
        display="flex"
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        as="button"
        onClick={() => hiddenInput.click()}
      >
        {fileName && getFileExtension(fileName) === acceptType ? (
          <Stack align="center" direction="row">
            <FaFileUpload fontSize={20} />
            <Text>{fileName}</Text>
          </Stack>
        ) : (
          <Stack align="center" direction="row">
            <FaUpload fontSize={20} />
            <Text>{children}</Text>
          </Stack>
        )}
      </Box>
    </Box>
  );
}
