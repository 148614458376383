import React from "react";
// import Carousel from "./carousel";
import ReverseHorizontalCard from "../Components/reverseHorizontalCard";
import HorizontalCard from "../Components/horizontalCard";

/* IMAGES FOR CARDS */
import portfolioCard from "../images/stock_portfolio.jpg";
import resumeCard from "../images/stock_resume_type.jpg";
import aboutCard from "../images/stock_about.jpg";
/* End IMAGES */

/* IMAGES FOR PROJECTS*/
import crypto_cross from "../images/crypto_cross.png"
import blockchain from "../images/blockchain.jpg"
import sorting_visualizer from "../images/sorting-visualizer.png";
import flappyBirds from "../images/flappy_birds.png";
import graph from "../images/graph-visual.png"
import predictatrailer from "../images/BAT.png"
import Card from "./heroCard";
import "./heroSection.css";

/* Typewriter effect function */
if (window.location.href === "https://www.andyczeropski.com/") {
  document.addEventListener("DOMContentLoaded", function (event) {
    // array with texts to type in typewriter
    var dataText = [
      "Data Scientist",
      "Mechanical Engineer",
      "Developer",
      "Data... Mechanical... Developer..?",
    ];
    var finalText = ["Problem Solver"];

    // type one text in the typwriter
    // keeps calling itself until the text is finished
    function typeWriter(text, i, fnCallback) {
      // chekc if text isn't finished yet
      if (i < text.length) {
        // add next character to p
        document.querySelector("p").innerHTML =
          text.substring(0, i + 1) + '<span aria-hidden="true"></span>';

        // wait for a while and call this function again for next character
        setTimeout(function () {
          typeWriter(text, i + 1, fnCallback);
        }, 70);
      }
      // text finished, call callback if there is a callback function
      else if (typeof fnCallback == "function") {
        // call callback after timeout
        setTimeout(fnCallback, 1500);
      }
    }

    /* Handles deleting the text */
    function typeDeleter(text, j, fnCallback) {
      // check if text is fully deleted
      if (j > 0) {
        // delete next character in p
        document.querySelector("p").innerHTML =
          text.substring(0, j - 1) + '<span aria-hidden="true"></span>';

        // wait for a while and call this function again for next character
        setTimeout(function () {
          typeDeleter(text, j - 1, fnCallback);
        }, 50);
      }
      // text finished, call callback if there is a callback function
      else if (typeof fnCallback == "function") {
        // call callback after timeout
        setTimeout(fnCallback, 300);
      }
    }

    /* For writing the final text seen on the screen */
    function finalWriter(text, i) {
      // check if text isn't finished yet
      if (i < text.length) {
        // add next character to p
        document.querySelector("p").innerHTML =
          text.substring(0, i + 1) + '<span aria-hidden="true"></span>';

        // wait for a while and call this function again for next character
        setTimeout(function () {
          finalWriter(text, i + 1);
        }, 70);
      }
    }

    // start a typewriter animation for a text in the dataText array
    function StartTextAnimation(i) {
      if (typeof dataText[i] == "undefined") {
        return finalWriter(finalText[0], 0);
      }
      // check if dataText[i] exists
      if (i < dataText[i].length) {
        // text exists! start typewriter animation
        typeWriter(dataText[i], 0, function () {
          // after callback (and whole text has been animated), start next text
          typeDeleter(dataText[i], dataText[i].length, function () {
            StartTextAnimation(i + 1);
          });
        });
      }
    }
    // start the text animation
    StartTextAnimation(0);
  });
}

export default function Hero() {
  return (
    <>
      <div className="hero-container">
        <div className="personal-details">
          <h2>Andy Czeropski</h2>
          <p id="text-animation">Data Scientist.</p>
        </div>
      </div>
      <div className="greeting">
        <p id="hero-thanks">Thanks for visiting my personal website.</p>
        <p> (Take a look around and feel free to leave some feedback.)</p>
      </div>
      <div className="card-container">
        <Card
          path="/portfolio"
          src={portfolioCard}
          alt="Link to Andy's portfolio"
          title="Portfolio"
          text="Check out some of the projects that I've worked on, and take a look at
          any projects that are currently on-going!"
          buttonText="Go to Portfolio"
        />
        <Card
          path="/resume"
          src={resumeCard}
          alt="Link to Andy's resume"
          title="Resume"
          text="Check out my digital resume - which includes my experience, roles, education, and more!"
          buttonText="Go to Resume"
        />
        <Card
          path="/about"
          src={aboutCard}
          alt="Link to Andy's about page"
          title="About Me"
          text="Take the opportunity to find out a little more about me - including hobbies, interests, and how to get in touch with me!"
          buttonText="Go to About"
        />
      </div>
      <div className="stock-code-photo" />
      <div className="hero-lower-container">
        <div className="carousel-about">
          <h2>Here are some of my latest projects... </h2>
        </div>
        <HorizontalCard
          path={{
            pathname: "https://predictatrailer.com",
          }}
          src={predictatrailer}
          href="https://predictatrailer.com"
          alt="predictatrailer.com - uses machine learning to predict car auctions"
          title="PredictATrailer.com"
          text="I created a web application that uses a machine learning model trained on 
          historical car auction results to predict the results of any BringATrailer.com auction
          results."
        />
        <ReverseHorizontalCard
          path= "/graph"
          src={graph}
          href="/graph"
          alt="Upload and Visualize Graph Network Data"
          title="Upload/Visualize Graph Network Data"
          text="I created a web application that allows users to upload .CSV data and
          visualize that data within a graph network."
        />
        <HorizontalCard
          path= "/crypto"
          src={crypto_cross}
          href="/crypto"
          alt="Crypto stock charts with buy/sell indicators"
          title="Cryptocurrency Charts w/ Technical Analysis"
          text="Check out my dynamic cryptocurrency charts with technical analysis. Buy/Sell
          indicators, double exponential moving averages, etc."
        />
        <ReverseHorizontalCard
          path={{
            pathname: "https://github.com/Tropskee/Blockchain",
          }}
          src={blockchain}
          href="https://github.com/Tropskee/Blockchain"
          alt="Create a small blockchain using Python"
          title="Creating a Small Blockchain using Python"
          text="Understand by doing! Learn how Blockchains (like Bitcoin and Ethereum) work
          by creating your own using Python and Flask."
        />
        <HorizontalCard
          path= "/sorting-visualizer"
          src={sorting_visualizer}
          href="/sorting-visualizer"
          alt="Sorting algorithm visualizer tool built in React"
          title="Sorting Algorithm Visualizer built in React"
          text="Check out my sorting algorithm visualizer with animation time scaling. Quick sort, merge sort, heap sort, 
          bubble sort, etc! *Not optimized for mobile phones*"
        />
        <ReverseHorizontalCard
          path={{ pathname: "https://github.com/Tropskee/NeatFlappyBird" }}
          src={flappyBirds}
          href="https://github.com/Tropskee/NeatFlappyBird"
          alt="Reinforcement Learning via Flappy Birds"
          title="Flappy Birds Reinforcement Learning"
          text="I employ reinforcement learning to beat the classic game
          of Flappy Birds using the NEAT (NeuroEvolution of Augmenting Topologies) algorithm."
        />
      </div>
    </>
  );
}
