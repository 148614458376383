// "use strict";
Object.defineProperty(exports, "__esModule", { value: true });
export default function drawLabel(context, data, settings) {
  if (!data.label) return;

  // base label values
  var size = data.size + 5;
  var font = settings.labelFont;
  var weight = settings.labelWeight;
  var color = data.color;
  context.font = "".concat(weight, " ").concat(size, "px ").concat(font);
  var PADDING = 2;

  // Then we draw the label background
  context.fillStyle = "rgba(255, 255, 255, 0.5)"; // set opacity

  // build box
  if (typeof data.label === "string") {
    var textWidth = context.measureText(data.label).width;
    var boxWidth = Math.round(textWidth);
    var nodeRadius = Math.max(data.size + 5, size / 2) + PADDING + 2;
    var boxHeight = nodeRadius + PADDING;
    var boxRadius = 4;
    var xDeltaCoord = Math.sqrt(Math.abs(Math.pow(nodeRadius, 2) - Math.pow(boxHeight / 2, 2)));

    // draw box
    context.beginPath();
    context.moveTo(data.x + xDeltaCoord + boxRadius, data.y + boxHeight / 2);
    context.lineTo(data.x + nodeRadius + boxWidth - boxRadius, data.y + boxHeight / 2);
    context.arcTo(
      data.x + nodeRadius + boxWidth,
      data.y + boxHeight / 2,
      data.x + nodeRadius + boxWidth,
      data.y + boxHeight / 2 - boxRadius,
      boxRadius
    );
    context.lineTo(data.x + nodeRadius + boxWidth, data.y - boxHeight / 2 + boxRadius);
    context.arcTo(
      data.x + nodeRadius + boxWidth,
      data.y - boxHeight / 2,
      data.x + nodeRadius + boxWidth - boxRadius,
      data.y - boxHeight / 2,
      boxRadius
    );
    context.lineTo(data.x + xDeltaCoord + boxRadius, data.y - boxHeight / 2);
    context.arcTo(
      data.x + xDeltaCoord,
      data.y - boxHeight / 2,
      data.x + xDeltaCoord,
      data.y - boxHeight / 2 + boxRadius,
      boxRadius
    );
    context.lineTo(data.x + xDeltaCoord, data.y + boxHeight / 2 - boxRadius);
    context.arcTo(
      data.x + xDeltaCoord,
      data.y + boxHeight / 2,
      data.x + xDeltaCoord + boxRadius,
      data.y + boxHeight / 2,
      boxRadius
    );
    context.closePath();
    context.fill();

    // add text color
    context.fillStyle = color;
  } else {
    context.beginPath();
    context.arc(data.x, data.y, data.size + PADDING, 0, Math.PI * 2);
    context.closePath();
    context.fill();
  }
  // finally, draw the label
  context.fillText(data.label, data.x + data.size + 3, data.y + size / 3);
}
