import React, { useEffect, useRef, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Center,
  SimpleGrid,
  Input,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import lightenHex from "./Utils/lightenHex";

export default function ColorPicker({
  graphColorPropertySelection,
  setGraphColorPropertySelection,
  nodeColorString,
  isEdge,
  colorIndex,
  header,
}) {
  const [colorSelection, setColorSelection] = useState("");

  // Create a ref for initialColor
  const initialColorRef = useRef();

  useEffect(() => {
    let initialColor;

    if (nodeColorString === "edgeColorSelection") {
      initialColor = graphColorPropertySelection?.edgeColorSelection?.[colorIndex] || "#C7C7C7";
      // Update the color selection state with the initialColor
      setColorSelection(initialColor);

      // Update the initialColorRef with the initialColor
      initialColorRef.current = initialColor;
    } else {
      const nodeColorSelection = graphColorPropertySelection?.nodeColorSelection?.[header];
      if (nodeColorString === "targetNodeColorSelection") {
        if (nodeColorSelection) {
          initialColor = nodeColorSelection;
        } else {
          initialColor = "#6B96DA";
        }
      } else if (nodeColorString === "sourceNodeColorSelection") {
        if (nodeColorSelection) {
          initialColor = nodeColorSelection;
        } else {
          initialColor = "#4CAF50";
        }
      }

      // Update the color selection state with the initialColor
      setColorSelection(initialColor);

      // Update the initialColorRef with the initialColor
      initialColorRef.current = initialColor;
    }

    // eslint-disable-next-line
  }, [graphColorPropertySelection]);

  const { isOpen, onToggle, onClose } = useDisclosure();

  let colors

  if (isEdge) {
    colors = [
      "#CECECE", // "gray.500",
      "#4CA6FF", // "blue.700",
      "#FF8C8C", // "gray.700",
      "#6FC4FF", // "blue.500",
      "#64DDBD", // "seafoam green",
      "#FFFF75", // "yellow.500",
      "#FFD075", // "orange.500",
      "#C757D7", // "purple.500",
      "#FF56A3", // "pink.500",
      "#FFA1C7"  // bright pink
  ]
  } else {
    colors = [
      "#9E9E9E", // "gray.500",
      "#1976D2", // "blue.700",
      "#E57373", // "gray.700",
      "#2196F3", // "blue.500",
      "#32AB8A", // "seafoam green",
      "#FFEB3B", // "yellow.500",
      "#FFA500", // "orange.500",
      "#9C27B0", // "purple.500",
      "#E91E63", // "pink.500"
      "#F1749E", // bright pink
    ];
  }

  function handleSubmitColor() {
    var reg = /^#([0-9a-f]{3}){1,2}$/i;
    var newColor = colorSelection;
    if (reg.test(colorSelection)) {
      setColorSelection(colorSelection);
    } else {
      setColorSelection("#6B96DA");
      newColor = "#6B96DA";
    }

    // check if color/header combo exists already - change header/color combo will change for all same header colors
    // if edge == color else change header/color combo to new color

    // Update setGraphColorPropertySelection
    if (nodeColorString === "edgeColorSelection") {
      // Create a copy of the edgeColorSelection array
      const updatedEdgeColorSelection = [...graphColorPropertySelection.edgeColorSelection];

      // Replace the element at the specified index
      updatedEdgeColorSelection[colorIndex] = newColor;

      // Update the state with the modified array
      setGraphColorPropertySelection({
        ...graphColorPropertySelection,
        edgeColorSelection: updatedEdgeColorSelection,
      });
    } else {
      setGraphColorPropertySelection({
        ...graphColorPropertySelection,
        nodeColorSelection: {
          ...graphColorPropertySelection.nodeColorSelection,
          [header]: newColor,
        },
      });
    }

    onClose();
  }

  return (
    <>
      <Center marginTop={2}>
        <Popover isOpen={isOpen} onClose={() => handleSubmitColor()} placement="top" variant="picker">
          <PopoverTrigger>
            <Button
              aria-label={colorSelection}
              background={colorSelection}
              height="28px"
              width="28px"
              p={0}
              minWidth="unset"
              borderRadius={3}
              onClick={onToggle}
              _hover={{bg: lightenHex(colorSelection, 30)}}
            />
          </PopoverTrigger>
          <PopoverContent width="170px">
            <PopoverArrow bg={colorSelection} />
            <PopoverCloseButton color="white" />
            <PopoverHeader
              height="100px"
              backgroundColor={colorSelection}
              borderTopLeftRadius={5}
              borderTopRightRadius={5}
              color="white"
            >
              <Center height="100%">{colorSelection}</Center>
            </PopoverHeader>
            <PopoverBody height="160px">
              <SimpleGrid columns={5} spacing={2}>
                {colors.map((c) => (
                  <Button
                    key={c}
                    aria-label={c}
                    background={c}
                    height="22px"
                    width="22px"
                    padding={0}
                    minWidth="unset"
                    borderRadius={3}
                    _hover={{ background: lightenHex(c, 30) }}
                    onClick={() => {
                      setColorSelection(c);
                    }}
                  ></Button>
                ))}
              </SimpleGrid>
              <Input
                borderRadius={3}
                marginTop={3}
                placeholder="#E91E63"
                size="sm"
                value={colorSelection}
                onChange={(e) => {
                  setColorSelection(e.target.value);
                }}
              />
              <Center>
                <Button
                  onClick={handleSubmitColor}
                  mt={3}
                  colorScheme="gray"
                  _hover={{ bg: lightenHex(colorSelection, 30) }}
                  color="white"
                  bg={colorSelection}
                  justifyContent="end"
                >
                  Submit
                </Button>
              </Center>
              <Center pt={2}>
                <Stack direction="row" spacing={2}></Stack>
              </Center>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Center>
    </>
  );
}
