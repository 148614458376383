import {
  Box,
  Button,
  Stack,
  Center,
  Text,
  Tooltip,
  Flex,
} from "@chakra-ui/react";
import LightenHex from "../Utils/lightenHex";

export default function GhostIconButton({
  onClick,
  Icon,
  isDisabled,
  children,
  tooltipText,
  color,
}) {
  return (
    <Tooltip
      label={tooltipText}
      openDelay={100}
      closeDelay={100}
      placement="top"
      hasArrow={false}
      shouldWrapChildren
    >
      <Flex alignItems="center" justifyContent="center">
        <Button
          isDisabled={isDisabled}
          onClick={onClick}
          variant="ghost"
          color={color ? color : "gray.600"}
          bg="white"
          _hover={{
            bg: "white",
            color: color ? LightenHex(color, 50) : "blue.200",
          }}
        >
          <Box>
            <Center>
              <Stack spacing={1}>
                <Center>
                  <Icon size="32px" />
                </Center>
                <Text fontWeight="bold" color="gray.700" fontSize="12px">
                  {children}
                </Text>
              </Stack>
            </Center>
          </Box>
        </Button>
      </Flex>
    </Tooltip>
  );
}
