/**
 * Handles the coloring of the graph based on the current colorByCommunity state.
 * If colorByCommunity is false, it loads the full graph with user defined node colors; otherwise, it colors closely grouped nodes with the same color.
 * @param {function} graphSettings - Object representing graph settings
 * @param {function} setGraphSettings - Callback function to set graph settings
 * @param {function} showToast - Function to show toast when user clicks color by community button
 */
export default async function handleColorByCommunity(
  graphSettings,
  setGraphSettings,
  showToast
) {
  graphSettings.colorByCommunity
    ? showToast("Loading Normally Colored Nodes..")
    : showToast("Loading Node Colors By Group..");
  await new Promise((resolve) => setTimeout(resolve, 10)); // this ensures the toast shows up before the graph starts to reload
  setGraphSettings({
    ...graphSettings,
    colorByCommunity: !graphSettings.colorByCommunity,
  });
}
