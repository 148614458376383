import React from "react";
import CalculatorApp from "./CalculatorApp";

export default function Calculator() {
  return (
    <div>
      <CalculatorApp />
    </div>
  );
}
