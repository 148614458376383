import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { toArray } from "lodash";

export default function DataTable({ isOpen, onClose, selectedData }) {
  const tableColumns = selectedData ? selectedData.meta.fields : null;

  return selectedData ? (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="blackAlpha.400" />
      <ModalContent minW="60%" maxW="90%" borderRadius={12}>
        <ModalHeader>Tabular Data Preview (50 rows)</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer w="100%" maxH="60vh" borderWidth={4} borderRadius={8} overflowY="scroll">
            <Table variant="striped">
              <TableCaption>End Tabular Data</TableCaption>
              <Thead>
                <Tr>
                  {tableColumns.map((item, idx) =>
                    item ? <Th key={tableColumns[idx] + idx}>{tableColumns[idx]}</Th> : null
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {selectedData.data.map((row, index) => (
                  <Tr key={index}>
                    {toArray(row).map((col, index) =>
                      tableColumns[index] ? <Td key={index + row + col}>{col}</Td> : null
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <Stack w="100%" align="center" direction="row" spacing={2}>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : null;
}
