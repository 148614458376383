import { GraphHome } from "../Components/GraphApp/GraphHome";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

// 2. Add your color mode config
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// 3. extend the theme
const theme = extendTheme({ config });

export default function GraphHomeV2() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <GraphHome />
      </ChakraProvider>
    </>
  );
}
