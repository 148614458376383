import { Box, Heading } from "@chakra-ui/react";

export default function ColoredBoxWithHeading({ color, children }) {
  return (
    <Box bg={color} py={2}>
      <Heading textAlign="center" size="md">
        {children}
      </Heading>
    </Box>
  );
}
