import {
  Text,
  Icon,
  Input,
  Stack,
  Select,
  Tooltip,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { MinusIcon } from "@chakra-ui/icons";
import ColorPicker from "../ColorPicker";
import { FaAngleRight } from "react-icons/fa";

export default function EdgeModalComponent({
  index,
  dataFields,
  sourceNode,
  targetNode,
  graphColorPropertySelection,
  setGraphColorPropertySelection,
  direction,
  setDirection,
  numEdges,
  edgeWeight,
  edgeWeightError,
  checkEdgeWeightValidity,
  edgeLabelInput,
  setEdgeLabelInput,
  edgeLabelTypeSelection,
  setEdgeLabelTypeSelection,
  SELECT_FONTSIZE,
  STATISTIC_FONTSIZE,
  CONNECTED_NODES_WIDTH,
  EDGE_WEIGHT_WIDTH,
  DIRECTION_WIDTH,
  EDGE_LABEL_WIDTH,
  EDGE_COLOR_WIDTH,
  CUSTOM_EDGE_LABEL_WIDTH,
}) {
  // remove sourceNode and targetNodes
  const filteredDataFields = dataFields.filter(
    (field) => field !== sourceNode && field !== targetNode
  );

  function handleSetDirection(selectedDirection) {
    const newDirectionArray = [...direction];
    newDirectionArray[index] = selectedDirection;
    setDirection(newDirectionArray);
  }

  function handleChangeEdgeState(newValue, index, edgeState, setEdgeState) {
    const newEdgeState = edgeState.map((currValue, i) =>
      i === index ? newValue : currValue
    );
    setEdgeState(newEdgeState);
  }

  function handleChangeEdgeLabelCustomToColumn(
    type,
    index,
    edgeType,
    setEdgeType,
    edgeLabel,
    setEdgeLabel
  ) {
    if (type === "column-edge-label") {
      handleChangeEdgeState(
        filteredDataFields[0],
        index,
        edgeLabel,
        setEdgeLabel
      );
    }

    handleChangeEdgeState(type, index, edgeType, setEdgeType);
  }

  return (
    <>
      <Stack
        key={`edge-container-stack-${index}`}
        align="start"
        display="flex"
        justify="space-between"
        spacing={0}
        direction="row"
        w="100%"
      >
        <Stack
          key={`connected-nodes-stack-${index}`}
          w={CONNECTED_NODES_WIDTH}
          pt={2}
          direction="row"
          spacing={2}
        >
          <Text
            fontWeight="bold"
            color={graphColorPropertySelection.nodeColorSelection[sourceNode]}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            key={`source-node-edge-text-${index}`}
          >
            {sourceNode}
          </Text>
          <Tooltip
            label="Defines the two node groups being connected"
            placement="top"
            key={`node-connections-tooltip-${index}`}
          >
            {
              <Stack
                key={`connected-nodes-line-arrow-stack-${index}`}
                spacing={0}
                direction="row"
                align="center"
              >
                <MinusIcon
                  color={graphColorPropertySelection.edgeColorSelection[index]}
                  fontSize="xl"
                  verticalAlign="center"
                  key={`node-connections-minus-icon-${index}`}
                />
                {direction[index] === "directed" && (
                  <Icon
                    color={
                      graphColorPropertySelection.edgeColorSelection[index]
                    }
                    as={FaAngleRight}
                    fontSize="lg"
                    verticalAlign="center"
                    key={`node-connections-arrow-icon-${index}`}
                    ml={-3}
                  />
                )}
              </Stack>
            }
          </Tooltip>
          <Text
            fontWeight="bold"
            color={graphColorPropertySelection.nodeColorSelection[targetNode]}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            key={`target-node-edge-text-${index}`}
          >
            {targetNode}
          </Text>
        </Stack>
        <Stack
          key={`edge-direction-stack-${index}`}
          direction="row"
          w={DIRECTION_WIDTH}
        >
          <Select
            key={`edge-direction-select-${index}`}
            value={direction[index]}
            onChange={(e) => handleSetDirection(e.target.value)}
            fontSize={SELECT_FONTSIZE}
          >
            <option key={`edge-direction-option-1-${index}`} value="undirected">
              Undirected
            </option>
            <option key={`edge-direction-option-2-${index}`} value="directed">
              Directed
            </option>
          </Select>
        </Stack>
        <Stack
          key={`edge-color-stack-${index}`}
          w={EDGE_COLOR_WIDTH}
          direction="column"
        >
          <ColorPicker
            key={`edge-color-picker-${index}`}
            graphColorPropertySelection={graphColorPropertySelection}
            setGraphColorPropertySelection={setGraphColorPropertySelection}
            nodeColorString="edgeColorSelection"
            isEdge={true}
            colorIndex={index}
            header={""}
          />
        </Stack>
        <Stack
          key={`edge-weight-stack-${index}`}
          direction="row"
          w={EDGE_WEIGHT_WIDTH}
          spacing={1}
        >
          <FormControl
            key={`edge-weight-form-control-${index}`}
            isInvalid={edgeWeightError}
            w="40%"
          >
            <Input
              key={`edge-weight-input-${index}`}
              isInvalid={edgeWeightError}
              value={edgeWeight[index]}
              onChange={(e) => {
                checkEdgeWeightValidity(e.target.value, index);
              }}
              fontSize={SELECT_FONTSIZE}
            />
            <FormErrorMessage key={`edge-weight-form-error-${index}`}>
              Value must be between 1 and 10
            </FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack
          key={`edge-label-type-stack-${index}`}
          direction="row"
          w={EDGE_LABEL_WIDTH}
        >
          <Select
            key={`edge-label-type-select-${index}`}
            // defaultValue={edgeLabelTypeSelection[index]}
            value={edgeLabelTypeSelection[index]}
            onChange={(e) =>
              handleChangeEdgeLabelCustomToColumn(
                e.target.value,
                index,
                edgeLabelTypeSelection,
                setEdgeLabelTypeSelection,
                edgeLabelInput,
                setEdgeLabelInput
              )
            }
            fontSize={SELECT_FONTSIZE}
          >
            <option
              key={`edge-label-type-option-1-${index}`}
              value="custom-edge-label"
            >
              Custom Edge Label
            </option>
            <option
              key={`edge-label-type-option-2-${index}`}
              value="column-edge-label"
            >
              Column Edge Label
            </option>
          </Select>
        </Stack>
        <Stack
          key={`edge-label-stack-${index}`}
          w={CUSTOM_EDGE_LABEL_WIDTH}
          direction="column"
        >
          {edgeLabelTypeSelection[index] === "custom-edge-label" ? (
            <Input
              key={`edge-label-input-${index}`}
              onChange={(e) =>
                handleChangeEdgeState(
                  e.target.value,
                  index,
                  edgeLabelInput,
                  setEdgeLabelInput
                )
              }
              fontSize={SELECT_FONTSIZE}
              value={edgeLabelInput === "" ? null : edgeLabelInput[index]}
              placeholder="Enter edge label"
            />
          ) : (
            <Select
              key={"edge-label-column-select-" + index}
              onChange={(e) => {
                handleChangeEdgeState(
                  e.target.value,
                  index,
                  edgeLabelInput,
                  setEdgeLabelInput
                );
              }}
              value={edgeLabelInput[index]}
              fontSize={SELECT_FONTSIZE}
            >
              {filteredDataFields.map((field, edgeLabelOptionIndex) => (
                <option
                  key={
                    field +
                    "-" +
                    edgeLabelOptionIndex +
                    "-edge-label-option-" +
                    index
                  }
                  value={field}
                >
                  {field}
                </option>
              ))}
            </Select>
          )}
          <Text
            pl={1}
            fontSize={STATISTIC_FONTSIZE}
            key={`num-edges-text-${index}`}
          >
            {Math.round(numEdges / 5)} Edges
          </Text>
        </Stack>
      </Stack>
    </>
  );
}
