import {
  Box,
  Button,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaRegQuestionCircle } from "react-icons/fa";
import LightenHex from "../Utils/lightenHex";

const ContactModal = ({ isOpen, setIsOpen, color }) => {
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="ghost"
        color={color}
        bg="white"
        _hover={{ bg: "white", color: LightenHex(color, 50) }}
      >
        <Box>
          <Center>
            <Stack spacing={1}>
              <Center>
                <FaRegQuestionCircle size="32px" />
              </Center>
              <Text fontWeight="bold" color="gray.700" fontSize="12px">
                CONTACT
              </Text>
            </Stack>
          </Center>
        </Box>
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Contact Us</ModalHeader>
          <ModalBody>
            <Text>
              If you have any questions or feature requests, please contact us at{" "}
              <a href="mailto:andrew.czeropski@bp.com">andrew.czeropski@bp.com</a>.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContactModal;
