// For submit button hovering, lightens a hex color by a user defined percent
export default function lightenHex(hex, percent) {
  if (hex) {
    hex = hex.toString();

    // Turn number percent into actual percent
    percent = percent / 100;

    // Remove the '#' symbol if present
    hex = hex.replace("#", "");

    // Convert to full 6-digit hex code if 3-digit hex code is provided
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((char) => `${char}${char}`)
        .join("");
    }

    // Convert hex to RGB values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Lighten RGB values
    const newR = Math.min(Math.round(r + (255 - r) * percent), 255);
    const newG = Math.min(Math.round(g + (255 - g) * percent), 255);
    const newB = Math.min(Math.round(b + (255 - b) * percent), 255);

    // Convert back to hex code
    const newHex = `#${newR.toString(16).padStart(2, "0")}${newG.toString(16).padStart(2, "0")}${newB
      .toString(16)
      .padStart(2, "0")}`;

    return newHex;
  }
}
