import {
  Text,
  Stack,
  Box,
  Select,
  Divider,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import ColorPicker from "../ColorPicker";
import { InfoIcon, MinusIcon, AddIcon } from "@chakra-ui/icons";
import lightenHex from "../Utils/lightenHex";

export default function NodeConnectionsStack({
  data,
  getFirstThreeEntries,
  getNodeCount,
  graphColorPropertySelection,
  handleAddSourceNode,
  handleAddTargetNode,
  handleRemoveSourceNode,
  handleRemoveTargetNode,
  handleSourceNodeChange,
  modalFileDelimiter,
  nodeConnections,
  SELECT_FONTSIZE,
  setGraphColorPropertySelection,
  setModalFileDelimiter,
  setNodeConnections,
  setNumEdges,
  sourceNodeCount,
  STATISTIC_FONTSIZE,
  TargetNodeSelect,
  elementWidth,
}) {
  return (
    <Stack
      direction="column"
      w={elementWidth}
      pl={2}
      pr={{ base: 4, md: 8 }}
      spacing={6}
    >
      {Object.keys(nodeConnections).map((sourceNode, sourceNodeIndex) => (
        <Stack
          p={6}
          w="100%"
          display="flex"
          justify="space-evenly"
          spacing={0}
          direction="row"
          key={`source-node-${sourceNode}`}
          borderColor={lightenHex(
            graphColorPropertySelection.nodeColorSelection[sourceNode],
            60
          )}
          borderWidth={1}
          bg={lightenHex(
            graphColorPropertySelection.nodeColorSelection[sourceNode],
            97
          )}
          borderRadius={12}
        >
          <Stack w="42%" direction="column">
            <Stack
              display="flex"
              justify="space-between"
              spacing={0}
              direction="row"
            >
              <Text size="sm" w="60%">
                Select Source Node{" "}
                {
                  <Tooltip
                    label="Object node(s) of Graph Network"
                    placement="top"
                  >
                    <InfoIcon verticalAlign="center" fontSize="xs" />
                  </Tooltip>
                }
              </Text>
              <Text size="sm" textAlign="center" w="40%">
                Source Color
              </Text>
            </Stack>
            <Divider borderColor="gray.300" borderWidth={1} mt={2} mb={4} />
            <Stack direction="row">
              <Stack
                w="60%"
                direction="column"
                key={"source-node-select-stack-" + sourceNodeIndex}
              >
                <Stack direction="row" w="100%">
                  {sourceNodeIndex !== 0 ? (
                    <IconButton
                      colorScheme={"red"}
                      size="sm"
                      bg="#DF4E43"
                      aria-label="Remove source node"
                      icon={<MinusIcon />}
                      onClick={() =>
                        handleRemoveSourceNode(
                          sourceNode,
                          sourceNodeIndex,
                          nodeConnections,
                          graphColorPropertySelection
                        )
                      }
                    />
                  ) : null}
                  <Select
                    key={"source-node-select-" + sourceNodeIndex}
                    bg="white"
                    onChange={(e) => {
                      handleSourceNodeChange(
                        e.target.value,
                        sourceNode,
                        nodeConnections,
                        setNodeConnections,
                        nodeConnections[sourceNode], // targetNodes
                        setNumEdges,
                        data,
                        graphColorPropertySelection,
                        setGraphColorPropertySelection
                      );
                    }}
                    fontSize={SELECT_FONTSIZE}
                    defaultValue={sourceNode}
                  >
                    {data.meta.fields.map((header) => {
                      // Check if the header equals sorceNode or is not in the nodeConnections object
                      if (
                        header === sourceNode ||
                        !Object.keys(nodeConnections).includes(header)
                      ) {
                        return (
                          <option key={header} value={header}>
                            {header}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </Stack>
                <Box>
                  <Stack
                    w="100%"
                    spacing={3}
                    direction="row"
                    key={"source-node-info-stack-" + sourceNodeIndex}
                  >
                    <Text
                      mr="auto"
                      maxW="30%"
                      pl={1}
                      fontSize={STATISTIC_FONTSIZE}
                    >
                      {getNodeCount(Object.keys(nodeConnections), data.data) +
                        " "}
                      Nodes
                    </Text>
                    <Text
                      key={
                        "source-node-first-three-entries-text-" +
                        sourceNodeIndex
                      }
                      ml="auto"
                      maxW="60%"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textAlign="right"
                      fontSize={STATISTIC_FONTSIZE}
                    >
                      {getFirstThreeEntries(sourceNode, data.data)}
                    </Text>
                  </Stack>
                </Box>
                <Box
                  key={"data-summary-add-node-box-" + sourceNodeIndex}
                  w="100%"
                >
                  {sourceNodeIndex === sourceNodeCount - 1 ? (
                    <Stack
                      key={
                        "data-summary-add-source-node-stack-" + sourceNodeIndex
                      }
                      py={2}
                      align="center"
                      spacing={3}
                      direction="row"
                    >
                      <IconButton
                        key={"add-source-node-button-" + sourceNodeIndex}
                        colorScheme={"green"}
                        bg="#5CAF7E"
                        size="sm"
                        aria-label="Add additional source nodes"
                        icon={<AddIcon />}
                        onClick={() => handleAddSourceNode(nodeConnections)}
                      />
                      <Text
                        key={
                          "data-summary-add-source-node-text-" + sourceNodeIndex
                        }
                        color="gray.400"
                      >
                        Click to add additional source nodes
                      </Text>
                    </Stack>
                  ) : null}
                </Box>
              </Stack>
              <Stack
                w="40%"
                direction="column"
                key={"source-node-color-stack-" + sourceNodeIndex}
              >
                <ColorPicker
                  key={"source-node-color-picker-" + sourceNodeIndex}
                  graphColorPropertySelection={graphColorPropertySelection}
                  setGraphColorPropertySelection={
                    setGraphColorPropertySelection
                  }
                  nodeColorString="sourceNodeColorSelection"
                  isEdge={false}
                  colorIndex={sourceNodeIndex}
                  header={sourceNode}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack w="42%" direction="column">
            <Stack
              key={"data-summary-modal-stack-" + sourceNodeIndex}
              display="flex"
              justify="space-between"
              spacing={0}
              direction="row"
            >
              <Text size="sm" w="60%">
                Select Target Node{" "}
                {
                  <Tooltip
                    label="Connecting Node(s) from Source Nodes"
                    placement="top"
                  >
                    <InfoIcon verticalAlign="center" fontSize="xs" />
                  </Tooltip>
                }
              </Text>
              <Text size="sm" textAlign="center" w="40%">
                Target Color
              </Text>
            </Stack>
            <Divider borderColor="gray.300" borderWidth={1} mt={2} mb={4} />
            {nodeConnections[sourceNode].map((targetNode, targetNodeIndex) => {
              let flattenedTargetNodeIndex = 0;
              if (sourceNodeIndex === 0) {
                flattenedTargetNodeIndex = targetNodeIndex;
              } else {
                Object.keys(nodeConnections).forEach((sn, snIdx) => {
                  if (snIdx < sourceNodeIndex) {
                    flattenedTargetNodeIndex += nodeConnections[sn].length;
                  }
                });
                flattenedTargetNodeIndex =
                  flattenedTargetNodeIndex + targetNodeIndex;
              }
              let numberOfTargetNodes = nodeConnections[sourceNode].length;
              return (
                <Stack
                  key={"data-summary-modal-stack-" + targetNodeIndex}
                  display="flex"
                  justify="space-between"
                  spacing={0}
                  direction="row"
                >
                  <Stack
                    key={"data-summary-modal-select-stack-" + targetNodeIndex}
                    w="60%"
                    direction="column"
                  >
                    <Stack direction="row" w="100%">
                      {targetNodeIndex !== 0 ? (
                        <IconButton
                          colorScheme={"red"}
                          size="sm"
                          bg="#DF4E43"
                          aria-label="Remove target node"
                          icon={<MinusIcon />}
                          onClick={() =>
                            handleRemoveTargetNode(
                              targetNode,
                              sourceNode,
                              targetNodeIndex,
                              flattenedTargetNodeIndex,
                              nodeConnections,
                              setNodeConnections,
                              graphColorPropertySelection,
                              setGraphColorPropertySelection
                            )
                          }
                        />
                      ) : null}
                      <TargetNodeSelect
                        sourceNode={sourceNode}
                        index={targetNodeIndex}
                        nodeConnections={nodeConnections}
                        data={data}
                        graphColorPropertySelection={
                          graphColorPropertySelection
                        }
                        setGraphColorPropertySelection={
                          setGraphColorPropertySelection
                        }
                      />
                    </Stack>
                    <Box
                      key={"data-summary-data-box-" + targetNodeIndex}
                      w="100%"
                    >
                      <Stack
                        key={"data-summary-data-stack-" + targetNodeIndex}
                        spacing={3}
                        direction="row"
                      >
                        <Text
                          key={"data-summary-data-text-" + targetNodeIndex}
                          mr="auto"
                          maxW="30%"
                          pl={1}
                          fontSize={STATISTIC_FONTSIZE}
                        >
                          {getNodeCount(targetNode, data.data) + " "}
                          Nodes
                        </Text>
                        <Text
                          key={"data-summary-data2-stack-" + targetNodeIndex}
                          ml="auto"
                          maxW="60%"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textAlign="right"
                          fontSize={STATISTIC_FONTSIZE}
                        >
                          {getFirstThreeEntries(targetNode, data.data)}
                        </Text>
                      </Stack>
                    </Box>
                    <Box
                      key={"data-summary-add-node-box-" + targetNodeIndex}
                      w="100%"
                    >
                      {targetNodeIndex === numberOfTargetNodes - 1 ? (
                        <Stack
                          key={"data-summary-add-node-stack-" + targetNodeIndex}
                          py={2}
                          align="center"
                          spacing={3}
                          direction="row"
                        >
                          <IconButton
                            key={"add-node-button-" + targetNodeIndex}
                            colorScheme={"green"}
                            bg="#5CAF7E"
                            size="sm"
                            aria-label="Add additional target nodes"
                            icon={<AddIcon />}
                            onClick={() =>
                              handleAddTargetNode(
                                sourceNode,
                                nodeConnections,
                                graphColorPropertySelection,
                                setGraphColorPropertySelection
                              )
                            }
                          />
                          <Text
                            key={
                              "data-summary-add-node-text-" + targetNodeIndex
                            }
                            color="gray.400"
                          >
                            Click to add additional target nodes
                          </Text>
                        </Stack>
                      ) : null}
                    </Box>
                  </Stack>
                  <Stack
                    key={
                      "data-summary-target-node-props-stack-" + targetNodeIndex
                    }
                    w="40%"
                    direction="column"
                  >
                    <ColorPicker
                      key={"data-summary-color-picker-" + targetNodeIndex}
                      graphColorPropertySelection={graphColorPropertySelection}
                      setGraphColorPropertySelection={
                        setGraphColorPropertySelection
                      }
                      nodeColorString="targetNodeColorSelection"
                      isEdge={false}
                      colorIndex={flattenedTargetNodeIndex}
                      header={targetNode}
                    />
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
          <Stack w="16%" direction="column">
            <Stack
              key={"data-summary-delimiter-stack-" + sourceNodeIndex}
              display="flex"
              justify="space-between"
              spacing={0}
              direction="row"
            >
              <Text size="sm" w="100%">
                Text Delimiter{" "}
                {
                  <Tooltip
                    label="How to split text within one cell"
                    placement="top"
                  >
                    <InfoIcon verticalAlign="center" fontSize="xs" />
                  </Tooltip>
                }
              </Text>
            </Stack>
            <Divider borderColor="gray.300" borderWidth={1} mt={2} mb={4} />
            <Box key={"source-node-delimiter-box-" + sourceNodeIndex} w="100%">
              <Select
                w="100%"
                bg="white"
                isDisabled={sourceNodeIndex > 0}
                value={modalFileDelimiter}
                key={"source-node-delimiter-select-" + sourceNodeIndex}
                onChange={(e) => {
                  setModalFileDelimiter(e.target.value);
                }}
              >
                <option value=",">Comma Delimited</option>
                <option value=";">Semi-Colon Delimited</option>
                <option value={false}>No Delimiter</option>
              </Select>
            </Box>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
