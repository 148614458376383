import { FaCamera } from "react-icons/fa";
import SaveAsPNG from "../GraphUtils/SaveAsPNG";

function GraphScreenshotButton({ isDisabled, sigmaInstance, ButtonComponent, color, tooltipText }) {
  const layers = ["edges", "nodes", "edgeLabels", "labels"];

  const getImage = () => SaveAsPNG(sigmaInstance, layers);

  return (
    <>
      <ButtonComponent
        tooltipText={tooltipText}
        isDisabled={isDisabled}
        Icon={FaCamera}
        color={color}
        onClick={() => {
          getImage();
        }}
      >
        SCREENSHOT
      </ButtonComponent>
    </>
  );
}

export default GraphScreenshotButton;
