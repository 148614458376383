import {
  Box,
  Circle,
  Divider,
  Heading,
  Stack,
  Text,
  Icon,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useEffect, useState } from "react";

export const Legend = ({ graphColorProperties, graphSettings }) => {
  const [showLegend, setShowLegend] = useState(true);

  const [colorSet, setColorSet] = useState(new Set());

  useEffect(() => {
    // Check if graphSettings.graphData._nodes is loaded before using it, and create a set of colors used in the graph to display in the legend
    if (graphSettings.graphData?._nodes) {
      const newColorSet = new Set(
        [...graphSettings.graphData._nodes.values()]
          .filter((item) => item && item.attributes.color)
          .map((item) => item.attributes.color)
      );

      setColorSet(newColorSet);
    }
  }, [graphSettings.graphData]);

  const toggleLegend = () => {
    setShowLegend(!showLegend);
  };

  return (
    <Box
      px={4}
      pb={4}
      pt={2}
      borderRadius={8}
      borderColor="gray.300"
      borderWidth={1}
      position="absolute"
      top="0.5rem"
      right="0.5rem"
    >
      <Stack spacing={1} direction="column">
        <Stack direction="row" display="flex">
          <Heading color="gray.600" size="sm">
            Color Legend
          </Heading>
          <Tooltip
            hasArrow={true}
            placement="top"
            label={showLegend ? "Hide" : "Show"}
          >
            <Button
              size="sm"
              onClick={toggleLegend}
              variant="link"
              display="flex"
              alignItems="center"
              ml="auto" // This will push the button to the right
            >
              <Icon as={showLegend ? FaEyeSlash : FaEye} mx={4} />
            </Button>
          </Tooltip>
        </Stack>
        {showLegend && (
          <>
            <Box mb={2}>
              <Divider borderWidth={1} borderColor="gray.300" />
            </Box>
            {graphColorProperties &&
              Object.keys(graphColorProperties.nodeColors).map(
                (node, index) => {
                  if (!colorSet.has(graphColorProperties.nodeColors[node])) {
                    return null;
                  }
                  return (
                    <Stack direction="row" key={index} align="center">
                      <Circle
                        size="18px"
                        bg={graphColorProperties.nodeColors[node]}
                        color="white" // Text color
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="full" // Makes it a perfect circle
                      />
                      <Text color={graphColorProperties.nodeColors[node]}>
                        {node}
                      </Text>
                    </Stack>
                  );
                }
              )}
          </>
        )}
      </Stack>
    </Box>
  );
};
