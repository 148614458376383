import { Box, Text } from "@chakra-ui/react";

export default function DataPlaceholder({ LEFT_CONTROL_TAB_WIDTH }) {
  // Creates a placeholder until the user has uploaded data
  return (
    <Box
      display="flex"
      h="100%"
      w={`calc(100vw - ${LEFT_CONTROL_TAB_WIDTH})`}
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize="2rem" fontWeight="bold" color="gray.200">
        IMPORT DATA TO VISUALIZE GRAPH NETWORK
      </Text>
    </Box>
  );
}
