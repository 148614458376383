/**
 * Handles the cropping of the graph based on the current cropGraph state.
 * If cropGraph is false, it loads the full graph; otherwise, it loads the graph cropped to its largest connected component.
 * @param {function} graphSettings - Object representing graph settings
 * @param {function} setGraphSettings - Callback function to set graph settings
 * @param {function} ShowToast - Function to show toast when user clicks crop graph button
 */
export default async function handleForceLabels(
  graphSettings,
  setGraphSettings,
  ShowToast
) {
  graphSettings.forceAnnotations
    ? ShowToast("Forcing labels to always show...")
    : ShowToast("Letting labels dynamically show based on zoom...");
  await new Promise((resolve) => setTimeout(resolve, 10)); // this ensures the toast shows up before the graph starts to reload
  setGraphSettings({
    ...graphSettings,
    forceAnnotations: !graphSettings.forceAnnotations,
  });
}
