/**
 * Download a file when the text element is clicked.
 *
 * @param {string} file - The URL or data of the file to download.
 * @param {string} fileName - The desired file name for the downloaded file.
 */
export default function DownloadFile({ file, fileName }) {
  /**
   * Handles the file download when the element is clicked.
   */
  const link = document.createElement("a");
  link.href = file;
  link.download = fileName;
  link.click();
}
