import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Heading,
  UnorderedList,
  ListItem,
  Image,
  VStack,
} from "@chakra-ui/react";
import DownloadFile from "../DownloadFile";
import excelFormattingExample from "../../assets/images/graph-import-example-tiny.png";
import graphTemplate from "../../assets/data/Graph-Template.xlsx";
import FileDropBox from "../FileDropBox";

/**
 * DataImportModal component allows the user to upload a csv/excel file from their computer to be displayed as a graph visualization
 *
 * @component
 * @param {Object} - The component
 * @param {boolean} isOpen - Flag indicating whether the analysis drawer is open or not.
 * @param {Function} onClose - The function to close the analysis drawer.
 * @param {RefObject} hiddenInputExcel - The hidden excel input element used for file upload.
 * @param {RefObject} hiddenInputCsv - The hidden csv input element used for file upload.
 * @param {string} fileName - The name of the selected file.
 * @param {string} file - The url or data of the uploaded file.
 * @param {Function} handleDataUpload - The callback function to handle data upload.
 * @param {Function} onInputChange - The callback function to handle file input change.
 * @returns {JSX.Element} The rendered DataImportModal component.
 */
export default function DataImportModal({
  isOpen,
  onClose,
  hiddenInputExcel,
  hiddenInputCsv,
  fileName,
  file,
  handleDataUpload,
  onInputChange,
}) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay bg="blackAlpha.400" />
      <ModalContent minW={{ base: "60%", "2xl": "40%" }} borderRadius={12}>
        <ModalHeader>Import data</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="50vh" overflowY="auto">
          <Tabs>
            <TabList>
              <Tab>Formatting</Tab>
              <Tab>CSV File</Tab>
              <Tab>Excel File</Tab>
              {/* <Tab>JSON</Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <VStack align="start" spacing={3}>
                  <Heading
                    color="green.400"
                    fontWeight="semibold"
                    size="sm"
                    mb={1}
                  >
                    EXCEL EXAMPLE (click to download template):
                  </Heading>
                  <Box
                    cursor="pointer"
                    onClick={() =>
                      DownloadFile({
                        file: graphTemplate,
                        fileName: "graph-bp-template.xlsx",
                      })
                    }
                  >
                    <Image
                      src={excelFormattingExample}
                      alt="Excel Formatting Example"
                      borderRadius="8px"
                      border="1px"
                      borderColor="gray.300"
                      maxW="100%"
                      h="auto"
                      transition="transform 0.3s, opacity 0.3s"
                      _hover={{
                        opacity: 0.6,
                        transform: "scale(1.02)",
                      }}
                    />
                  </Box>
                  <Heading
                    color="blue.500"
                    fontWeight="semibold"
                    pt={5}
                    size="sm"
                    mb={1}
                  >
                    NOTE:
                  </Heading>
                  <UnorderedList>
                    <ListItem ml={4}>
                      This will only import the first worksheet in your
                      csv/excel workbook.
                    </ListItem>
                    <ListItem ml={4}>
                      Please navigate to the "CSV File" or "Excel File" tabs to
                      import your relevant file.
                    </ListItem>
                    <ListItem ml={4}>
                      Feel free to download the excel template above and
                      copy/paste your own data.
                    </ListItem>
                  </UnorderedList>
                  <Text mt={1}>
                    If you are still having trouble importing data, please use
                    the "Contact" button to reach out to our technical team.
                  </Text>
                </VStack>
              </TabPanel>
              <TabPanel>
                <Box mt={4} h="150px" w="100%">
                  <FileDropBox
                    acceptType=".csv"
                    hiddenInput={hiddenInputCsv}
                    onInputChange={onInputChange}
                    fileName={fileName}
                  >
                    Click to Upload CSV File
                  </FileDropBox>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box mt={4} h="150px" w="100%">
                  <FileDropBox
                    acceptType=".xlsx"
                    hiddenInput={hiddenInputExcel}
                    onInputChange={onInputChange}
                    fileName={fileName}
                  >
                    Click to Upload Excel File
                  </FileDropBox>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Stack w="100%" align="center" direction="row" spacing={2}>
            <Box ml={4} mr="auto" maxW="55%" textAlign="left">
              {fileName ? (
                <Stack mr="auto" direction="row">
                  <Text fontWeight="bold">File: </Text>
                  <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                  >
                    {fileName}
                  </Text>
                </Stack>
              ) : null}
            </Box>
            <Button
              isDisabled={!file}
              colorScheme="blue"
              onClick={() => handleDataUpload(file)}
            >
              Import
            </Button>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
