/**
 * Load csv data and create a visual graph network
 */

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  Flex,
  Box,
  useDisclosure,
  Stack,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  FaFileUpload,
  FaFileExport,
  FaListUl,
  FaTags,
  FaTable,
  FaUsers,
  FaUsersSlash,
  FaWindowClose,
  FaUndo,
} from "react-icons/fa";
import { BsShareFill } from "react-icons/bs";
import DataPlaceholder from "./Components/DataPlaceholder";
import MultiStepDataImportModal from "./Components/Modals/MultiStepDataImportModal";
import ContactModal from "./Components/Modals/ContactModal";
import DataTable from "./Components/DataTable";
import GraphScreenshotButton from "./Components/Common/GraphScreenshotButton";
import { CSVLink } from "react-csv";
import GhostIconButton from "./Components/Common/GhostIconButton";
import ColoredBoxWithHeading from "./Components/Common/ColoredBoxWithHeading";
import GraphContainer from "./Components/GraphUtils/GraphContainer";
import handleColorByCommunity from "./Components/Utils/handleColorByCommunity";
import handleCropGraph from "./Components/Utils/handleCropGraph";
import handleForceLabels from "./Components/Utils/handleForceLabels";
import unicornCSV from "../GraphApp/assets/data/research-unicorn-companies.csv";
import Papa from "papaparse";

export const GraphHome = () => {
  const toast = useToast();
  const file = unicornCSV;

  // Data States
  const [selectedData, setSelectedData] = useState(null);
  const [filteredSelectedData, setFilteredSelectedData] = useState(null);
  const [filteredExportData, setFilteredExportData] = useState(null);

  // Modal States
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDataTableOpen,
    onOpen: onDataTableOpen,
    onClose: onDataTableClose,
  } = useDisclosure();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  // Node info drawer state
  const {
    isOpen: isAnalysisDrawerOpen,
    onOpen: onAnalysisDrawerOpen,
    onClose: onAnalysisDrawerClose,
  } = useDisclosure();

  // Data import modal - Step 1 is selecting data file, step 2 is selecting features from the data to use in the graph
  const [step, setStep] = useState(1);

  // GRAPH STATES

  const [graphColorProperties, setGraphColorProperties] = useState({
    nodeColors: null,
    edgeColors: ["#C7C7C7"],
  });

  // graph node data columns/properties
  const [graphNodeProperties, setGraphNodeProperties] = useState({
    sourceNodePropsSelection: null, // properties to display for a given source node
    nodeConnections: null,
    fileDelimiter: ",",
  });

  // graph edge data columns/properties
  const [graphEdgeProperties, setGraphEdgeProperties] = useState({
    edgeLabel: [""],
    edgeLabelType: ["custom-edge-label"],
    edgeWeight: [1],
    graphDirection: ["undirected"],
  });

  // graph node events
  const [graphEvents, setGraphEvents] = useState({
    hoveredNode: null,
    clickedNode: null,
  });

  // general graph states
  const [graphSettings, setGraphSettings] = useState({
    graphData: null,
    sigmaInstance: null,
    colorByCommunity: false,
    cropGraph: false,
    isCircular: false,
    forceAnnotations: false,
  });

  // Width of the control tab containing user selectable graph buttons
  const LEFT_CONTROL_TAB_WIDTH = "7rem";
  const GHOST_ICON_COLOR = "#6C757D";

  function showToast(title) {
    toast({
      title: title,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  }

  async function resetGraphParameters() {
    setGraphEvents({
      hoveredNode: null,
      clickedNode: null,
    });
    setGraphSettings({
      graphData: null,
      sigmaInstance: null,
      isCircular: false,
      colorByCommunity: false,
      cropGraph: false,
      forceAnnotations: false,
    });

    setGraphColorProperties({
      nodeColors: null,
      edgeColors: ["#C7C7C7"],
    });

    setGraphEdgeProperties({
      edgeLabel: [""],
      edgeLabelType: ["custom-edge-label"],
      edgeWeight: [1],
      graphDirection: ["undirected"],
    });

    onAnalysisDrawerClose();
    setSelectedData(null);
    setStep(1);

    setGraphNodeProperties({
      ...graphNodeProperties,
      nodeConnections: null,
    });
  }

  async function handleResetGraph() {
    await resetGraphParameters();
  }

  useEffect(() => {
    if (!selectedData) {
      // update final variables to build graph
      toast({
        title: "Loading Unicorn Companies Graph...",
        status: "info",
        duration: 3000,
        isClosable: true,
      });

      Papa.parse(file, {
        download: true,
        header: true,
        delimiter: ",",
        complete: (results) => {
          const dataColumns = results.meta.fields;
          setGraphColorProperties({
            ...graphColorProperties,
            nodeColors: {
              [dataColumns[0]]: "#4CAF50",
              [dataColumns[1]]: "#6B96DA",
            },
          });

          setGraphNodeProperties({
            ...graphNodeProperties,
            nodeConnections: {
              [dataColumns[0]]: [dataColumns[1]],
              [dataColumns[1]]: [dataColumns[0]],
            },
            sourceNodePropsSelection: dataColumns.slice(2),
          });

          setGraphEvents({
            ...graphEvents,
            clickedNode: null,
          });

          setSelectedData(results);
          setFilteredExportData(results);
          setFilteredSelectedData(results);
        },
      });
    }
  }, []);

  return (
    <Flex h="calc(100vh - 5rem)">
      <Stack direction="row" spacing={0}>
        <Box
          w={LEFT_CONTROL_TAB_WIDTH}
          pb={1}
          borderRight="2px"
          borderColor="gray.200"
          justify="center"
          alignItems="center"
        >
          <Stack direction="column" spacing={8} overflowY="auto" h="100%">
            <ColoredBoxWithHeading color="gray.200">DATA</ColoredBoxWithHeading>
            <GhostIconButton
              tooltipText="Cancel Graph"
              Icon={FaWindowClose}
              color="#CD4751"
              onClick={() => handleResetGraph()}
            >
              CANCEL
            </GhostIconButton>
            <GhostIconButton
              tooltipText="Import Your Data"
              Icon={FaFileUpload}
              color="#4285F4"
              onClick={() => {
                handleResetGraph();
                onOpen();
                setGraphEvents({
                  ...graphEvents,
                  clickedNode: null,
                });
              }}
            >
              IMPORT
            </GhostIconButton>
            <MultiStepDataImportModal
              setSelectedData={setSelectedData}
              setFilteredSelectedData={setFilteredSelectedData}
              setFilteredExportData={setFilteredExportData}
              graphNodeProperties={graphNodeProperties}
              setGraphNodeProperties={setGraphNodeProperties}
              isOpen={isOpen}
              onClose={onClose}
              graphColorProperties={graphColorProperties} // new
              setGraphColorProperties={setGraphColorProperties} // new
              graphEdgeProperties={graphEdgeProperties}
              setGraphEdgeProperties={setGraphEdgeProperties}
              graphEvents={graphEvents}
              setGraphEvents={setGraphEvents}
              step={step}
              setStep={setStep}
              resetGraph={handleResetGraph}
            />
            {selectedData ? (
              <Center>
                <CSVLink
                  data={filteredExportData.data}
                  filename={"exported-graph-data.csv"}
                >
                  <GhostIconButton
                    tooltipText="Export Selected Data"
                    Icon={FaFileExport}
                    color="#34A853"
                  >
                    EXPORT
                  </GhostIconButton>
                </CSVLink>
              </Center>
            ) : (
              <GhostIconButton
                tooltipText="Export Selected Data"
                isDisabled={!selectedData}
                Icon={FaFileExport}
                color="#34A853"
              >
                EXPORT
              </GhostIconButton>
            )}
            <ColoredBoxWithHeading color="gray.200">
              GRAPH
            </ColoredBoxWithHeading>
            <GhostIconButton
              tooltipText="View Data Tab"
              isDisabled={!selectedData}
              Icon={FaListUl}
              color={GHOST_ICON_COLOR}
              onClick={() => {
                setStep(2);
                onOpen();
                onAnalysisDrawerClose();
                setGraphEvents({
                  ...graphEvents,
                  clickedNode: null,
                });
              }}
            >
              DATA
            </GhostIconButton>
            <GhostIconButton
              tooltipText="View Data Table"
              isDisabled={!selectedData}
              Icon={FaTable}
              color={GHOST_ICON_COLOR}
              onClick={onDataTableOpen}
            >
              TABLE
            </GhostIconButton>
            <GhostIconButton
              tooltipText="Group-based Node Coloring"
              isDisabled={!selectedData}
              Icon={graphSettings.colorByCommunity ? FaUsersSlash : FaUsers}
              color={GHOST_ICON_COLOR}
              onClick={() =>
                handleColorByCommunity(
                  graphSettings,
                  setGraphSettings,
                  showToast
                )
              }
            >
              {graphSettings.colorByCommunity ? "UN-GROUP" : "GROUP NODES"}
            </GhostIconButton>
            <GhostIconButton
              tooltipText="Toggle between always showing node labels or dynamically showing node labels"
              isDisabled={!selectedData}
              Icon={FaTags}
              color={GHOST_ICON_COLOR}
              onClick={() =>
                handleForceLabels(graphSettings, setGraphSettings, showToast)
              }
            >
              {graphSettings.forceAnnotations ? "DYN. LABELS" : "FORCE LABELS"}
            </GhostIconButton>
            <GhostIconButton
              tooltipText="Crop Graph To Largest Connected Component"
              isDisabled={!selectedData}
              Icon={graphSettings.cropGraph ? FaUndo : BsShareFill}
              color={GHOST_ICON_COLOR}
              onClick={() =>
                handleCropGraph(graphSettings, setGraphSettings, showToast)
              }
            >
              {graphSettings.cropGraph ? "UN-CROP" : "CROP"}
            </GhostIconButton>
            <DataTable
              isOpen={isDataTableOpen}
              onClose={onDataTableClose}
              selectedData={
                selectedData
                  ? { ...selectedData, data: selectedData.data.slice(0, 50) }
                  : null
              }
            />
            <GraphScreenshotButton
              tooltipText="Screenshot the graph"
              isDisabled={!selectedData}
              sigmaInstance={graphSettings.sigmaInstance}
              ButtonComponent={GhostIconButton}
              color={GHOST_ICON_COLOR}
            />
            <ColoredBoxWithHeading color="gray.200">HELP</ColoredBoxWithHeading>
            <ContactModal
              isOpen={isContactModalOpen}
              setIsOpen={setIsContactModalOpen}
              color="#CD4751"
            />
          </Stack>
        </Box>
        {selectedData ? (
          <Box h="100%" w="100%">
            <GraphContainer
              selectedData={selectedData}
              filteredSelectedData={filteredSelectedData}
              graphNodeProperties={graphNodeProperties}
              graphColorProperties={graphColorProperties}
              graphEdgeProperties={graphEdgeProperties}
              graphEvents={graphEvents}
              setGraphEvents={setGraphEvents}
              graphSettings={graphSettings}
              setGraphSettings={setGraphSettings}
              isAnalysisDrawerOpen={isAnalysisDrawerOpen}
              onAnalysisDrawerClose={onAnalysisDrawerClose}
              onAnalysisDrawerOpen={onAnalysisDrawerOpen}
              LEFT_CONTROL_TAB_WIDTH={LEFT_CONTROL_TAB_WIDTH}
            />
          </Box>
        ) : (
          <DataPlaceholder LEFT_CONTROL_TAB_WIDTH={LEFT_CONTROL_TAB_WIDTH} />
        )}
      </Stack>
    </Flex>
  );
};
