import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollTop from "./Components/scrollTop";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import NavBar from "./Components/navbar";
import Footer from "./Components/footer";
import Home from "./Pages/home";
import About from "./Pages/about";
import Resume from "./Pages/resume";
import Portfolio from "./Pages/portfolio";
import Calculator from "./Components/Calculator/Calculator";
import SortingAlgorithmVisualizer from "./Components/SortingVisualizer/SortingAlgorithmVisualizer";
import GraphHomeV2 from "./Pages/GraphHomeV2";
import ChartData from "./Components/CryptoCross/ChartDataV2";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

// Google Analytics
ReactGA.initialize("UA-193128227-1");
function App() {
  const location = useLocation();
  // Fired on every route change
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <Router>
        <ScrollTop />
        <NavBar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/resume" exact component={Resume} />
          <Route path="/graph" exact component={GraphHomeV2} />
          <Route path="/portfolio" exact component={Portfolio} />
          <Route path="/calculator" exact component={Calculator} />
          <Route
            path="/sorting-visualizer"
            exact
            component={SortingAlgorithmVisualizer}
          />
          <Route path="/crypto" exact component={ChartData} />
        </Switch>
        {location.pathname !== "/graph" && <Footer />}
      </Router>
    </>
  );
}

export default App;
