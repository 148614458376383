/* eslint no-sequences: 0 */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import { StockChart } from "./ChartTypes";
import "./ChartData.css";

// Load Highcharts modules
// DO NOT DELETE
require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/ema")(Highcharts);
require("highcharts/indicators/dema")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

export default function ChartDataV2() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [actions, setActions] = useState(["Unsure"]);
  const [ohlc, setOhlc] = useState([0, 0, 0, 0, 0]);
  const [investment, setInvestment] = useState(1000);
  const [fee, setFee] = useState(0.005);
  const [ticker, setTicker] = useState("BTC-USD");
  const [period, setPeriod] = useState("15d");
  const [interval, setInterval] = useState("60m");
  const [options, setOptions] = useState({
    title: {
      text: `${ticker} chart`,
    },
    colors: ["lightGreen", "orange"],
    chart: {
      borderColor: "#47c5d8",
      borderRadius: 20,
      borderWidth: 2,
      type: "candlestick", // Change the chart type to 'candlestick'
    },
    legend: {
      enabled: true,
    },
    navigator: {
      maskFill: "rgba(180, 198, 220, 0.75)",
      series: {
        color: "#00B7E5",
        fillOpacity: 0.05,
      },
    },
    series: [
      {
        type: "candlestick",
        name: "OHLC Pricing",
        id: "crypto",
        color: "red",
        upColor: "green",
        pointPadding: 0.005,
        data: [],
      },
      {
        type: "dema",
        linkedTo: "crypto",
        marker: false,
        lineWidth: 1,
        params: {
          period: 15,
        },
      },
      {
        type: "dema",
        linkedTo: "crypto",
        marker: false,
        lineWidth: 1,
        params: {
          period: 50,
        },
      },
    ],
  });

  useEffect(() => {
    let isCancelled = false;

    async function getData() {
      try {
        const response = await fetch(
          "https://tropskee.pythonanywhere.com/stocks/cross",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ticker: ticker,
              period: period,
              interval: interval,
            }),
          }
        );
        const data = await response.json();
        setOhlc(data);
        setIsLoaded(true);
        setOptions({
          ...options,
          title: {
            text: `${ticker} chart`,
          },
          series: [
            {
              ...options.series[0],
              data: data,
            },
            ...options.series.slice(1), // Keep the other series unchanged
          ],
        });
      } catch (error) {
        setIsLoaded(true);
        setError(error);
      }
    }

    getData();

    return () => {
      isCancelled = true;
    };
  }, [ticker, period, interval, options]);

  useEffect(() => {
    let isCancelled = false;

    async function getProfitData() {
      try {
        const response = await fetch(
          "https://tropskee.pythonanywhere.com/stocks/profit",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ticker: ticker,
              period: period,
              interval: interval,
              initial_investment: investment,
              fee: fee,
            }),
          }
        );
        const actionData = await response.json();
        setActions(actionData);
      } catch (error) {
        setError(error);
      }
    }

    getProfitData();

    return () => {
      isCancelled = true;
    };
  }, [ticker, period, interval, fee, investment]);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading, may take a few seconds...</div>;
  } else {
    return (
      <div className="crypto-container">
        <div className="stock-chart-container">
          <StockChart highcharts={Highcharts} options={options} />
        </div>
        <div className="stock-chart-forms">
          <form>
            <label>
              Pick a Crypto to track:&nbsp;
              <select
                value={ticker}
                onChange={(e) => setTicker(e.target.value)}
              >
                <option value="BTC-USD">Bitcoin</option>
                <option value="ETH-USD">Ethereum</option>
                <option value="DOGE-USD">Dogecoin</option>
                <option value="BNB-USD">Binance Coin</option>
                <option value="SHIB-USD">Shiba Inu</option>
                <option value="USDT-USD">Tether</option>
                <option value="XRP-USD">XRP</option>
                <option value="ADA-USD">Cardano</option>
              </select>
            </label>
          </form>
          <form>
            <label>
              Customize the period (in days):&nbsp;
              <select
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
              >
                <option value="2d">2 days</option>
                <option value="7d">7 days</option>
                <option value="15d">15 days (default)</option>
                <option value="30d">30 days</option>
                <option value="60d">60 days</option>
              </select>
            </label>
          </form>
          <form>
            <label>
              Customize the data time interval:&nbsp;
              <select
                value={interval}
                onChange={(e) => setInterval(e.target.value)}
              >
                <option value="5m">5 Minutes</option>
                <option value="15m">15 Minutes</option>
                <option value="30m">30 Minutes</option>
                <option value="60m">60 Minutes (default)</option>
              </select>
            </label>
          </form>
        </div>
        <div className="crypto-profit">
          <h2 className="crypto-profit-title">
            Profit Based on Trading the Moving Averages
          </h2>
          <p>
            How much money would you make buying/selling with the moving
            average?
          </p>
          <form>
            <label>
              Select your initial investment:&nbsp;
              <select
                value={investment}
                onChange={(e) => setInvestment(parseInt(e.target.value))}
              >
                <option value="0">$0</option>
                <option value="100">$100</option>
                <option value="1000">$1,000</option>
                <option value="10000">$10,000</option>
                <option value="20000">$20,000</option>
                <option value="100000">$100,000</option>
              </select>
            </label>
          </form>
          <form>
            <label>
              Select the broker fee for buying and selling:&nbsp;
              <select
                value={fee}
                onChange={(e) => setFee(parseFloat(e.target.value))}
              >
                <option value="0.001">0.10%</option>
                <option value="0.0025">0.25%</option>
                <option value="0.0035">0.35%</option>
                <option value="0.005">0.5% (default)</option>
                <option value="0.01">1.0%</option>
              </select>
            </label>
          </form>
          <div className="crypto-profit-gains">
            <p>
              In the past {period.slice(0, period.length - 1)} days you would
              have made:{" "}
              {parseInt(actions.slice(0, 1) - investment) >= 0 ? (
                <span
                  style={{
                    color: "#00931A",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  &nbsp;${parseInt(actions.slice(0, 1) - investment)}
                </span>
              ) : (
                <span
                  style={{
                    color: "#C00E00",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  &nbsp;${parseInt(actions.slice(0, 1) - investment)}
                </span>
              )}
            </p>
            <p>
              You would have increased your initial investment by:{" "}
              {parseFloat(actions.slice(1, 2)) >= 0 ? (
                <span
                  style={{
                    color: "#00931A",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  &nbsp;{parseFloat(actions.slice(1, 2))}%
                </span>
              ) : (
                <span
                  style={{
                    color: "#C00E00",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  &nbsp;{parseFloat(actions.slice(1, 2))}%
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
