import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Stack,
  Box,
  Heading,
  Select,
  Divider,
  Checkbox,
  CheckboxGroup,
  Tooltip,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import EdgeModalComponent from "./EdgeModalComponent";
import NodeConnectionsStack from "../Common/NodeConnectionsStack";
import lightenHex from "../Utils/lightenHex";

/**
 * DataSummaryModal component displays a summary of the uploaded file's data and allows the user to select options for graph visualization.
 *
 * @component
 * @param {Array} data - The array of data extracted from the uploaded file.
 * @param {string} file - The URL or data of the uploaded file.
 * @param {string} fileDelimiter - The delimiter used to parse the data in the file.
 * @param {boolean} isOpen - Flag indicating whether the modal is open or not.
 * @param {Function} onClose - The callback function to close the modal.
 * @param {number} step - The current step in the data import process.
 * @param {Function} setStep - The function to update the current step.
 * @param {Object} nodeConnectionsSelection - The source and target node dict for the graph
 * @param {string} sourceNodePropsSelection - The selected source node properties for the graph.
 * @param {Object} graphEdgeProperties - The selected edge properties for the graph.
 * @param {number} initialNumEdges - The initial number of edges to display in the graph.
 * @param {Object} graphColors - The selected colors for the graph elements.
 * @param {Object} graphColorProperties - The selected colors for the graph elements.
 * @param {boolean} calcEdgeSize - Flag indicating whether to calculate edge size based on weight.
 * @param {Function} handleTargetNodeChange - The callback function to handle target node selection.
 * @param {Function} handleSourceNodeChange - The callback function to handle source node selection.
 * @param {Function} handleSubmitData - The callback function to submit the selected data for graph visualization.
 * @param {number} SELECT_FONTSIZE - The font size for select components.
 * @param {number} NODEWIDTH - The width of the node input fields.
 * @param {number} COLORWIDTH - The width of the color input fields.
 * @param {number} PROPWIDTH - The width of the property input fields.
 * @param {number} STATISTIC_FONTSIZE - The font size for statistics labels.
 * @param {function} resetGraph - Callback function to reset the graph.
 * @returns {JSX.Element} The rendered DataSummaryModal component.
 */

export default function DataSummaryModal({
  data,
  file,
  fileDelimiter,
  isOpen,
  onClose,
  step,
  setStep,
  nodeConnectionsSelection,
  sourceNodePropsSelection,
  graphEdgeProperties,
  initialNumEdges,
  graphColors,
  graphColorProperties,
  calcEdgeSize,
  handleTargetNodeChange,
  handleSourceNodeChange,
  handleSubmitData,
  SELECT_FONTSIZE,
  NODEWIDTH,
  COLORWIDTH,
  PROPWIDTH,
  STATISTIC_FONTSIZE,
  resetGraph,
}) {
  const [nodeConnections, setNodeConnections] = useState(
    nodeConnectionsSelection
  );
  const [sourceNodeProps, setSourceNodeProps] = useState(
    sourceNodePropsSelection
  );
  const [numEdges, setNumEdges] = useState(initialNumEdges);
  const [edgeLabelInput, setEdgeLabelInput] = useState(
    graphEdgeProperties.edgeLabel
  );
  const [direction, setDirection] = useState(
    graphEdgeProperties.graphDirection
  );
  const [edgeLabelTypeSelection, setEdgeLabelTypeSelection] = useState(
    graphEdgeProperties.edgeLabelType
  );
  const [customEdgeWeight, setCustomEdgeWeight] = useState(
    graphEdgeProperties.edgeWeight
  );
  const [edgeWeightError, setEdgeWeightError] = useState(false);

  const [graphColorPropertySelection, setGraphColorPropertySelection] =
    useState({
      nodeColorSelection: graphColorProperties.nodeColors,
      edgeColorSelection: graphColorProperties.edgeColors,
    });
  const [targetNodeCount, setTargetNodeCount] = useState(
    getTotalObjectLength(nodeConnections)
  );
  const [sourceNodeCount, setSourceNodeCount] = useState(
    Object.keys(nodeConnections).length
  );
  const [selectAll, setSelectAll] = useState(true); // for checkbox
  const [modalFileDelimiter, setModalFileDelimiter] = useState(fileDelimiter); // user selected delimiter
  const CONNECTED_NODES_WIDTH = "16%";
  const DIRECTION_WIDTH = "10%";
  const EDGE_COLOR_WIDTH = "10%";
  const EDGE_WEIGHT_WIDTH = "10%";
  const EDGE_LABEL_WIDTH = "10%";
  const CUSTOM_EDGE_LABEL_WIDTH = "20%";

  function getTotalObjectLength(obj) {
    return Object.values(obj).reduce(
      (accumulator, currentArray) => accumulator + currentArray.length,
      0
    );
  }

  /**
   * Checks if the user entered a valid edge weight number between 1 and 10.
   *
   * @param {string} weight - The edge weight value entered by the user.
   * @param {number} index - The index of the edge weight in the array.
   */
  function checkEdgeWeightValidity(weight, index) {
    if (weight === "") {
      setEdgeWeightError(false);
      const newCustomEdgeWeightArray = [...customEdgeWeight];
      newCustomEdgeWeightArray[index] = weight;
      setCustomEdgeWeight(newCustomEdgeWeightArray);
    } else {
      const weightInt = parseInt(weight, 10);
      if (isNaN(weightInt) || weightInt < 1 || weightInt > 10) {
        setEdgeWeightError(true);
      } else {
        setEdgeWeightError(false);
        const newCustomEdgeWeightArray = [...customEdgeWeight];
        newCustomEdgeWeightArray[index] = weightInt;
        setCustomEdgeWeight(newCustomEdgeWeightArray);
      }
    }
  }

  /**
   * Adds an additional target node and handles setting up states for new target node
   *
   * @param {string} sourceNode - sourceNode name
   * @returns {array} - The updated newSourceNodeProps array
   */
  const handleAddTargetNode = (
    sourceNode,
    nodeConnections,
    graphColorPropertySelection,
    setGraphColorPropertySelection
  ) => {
    console.log(graphColorPropertySelection);
    const availableTargetNodes = data.meta.fields.filter(
      (header) =>
        header !== sourceNode && !nodeConnections[sourceNode].includes(header)
    );

    const newTargetNode = availableTargetNodes[0];

    if (availableTargetNodes.length < 1) {
      alert("No columns left to add");
      return;
    }

    setNodeConnections((prevNodeConnections) => ({
      ...prevNodeConnections,
      [sourceNode]: [...prevNodeConnections[sourceNode], newTargetNode],
    }));

    // Check if node header already has a color associated with it, if not assign default
    if (
      !Object.keys(graphColorPropertySelection.nodeColorSelection).includes(
        newTargetNode
      )
    ) {
      setGraphColorPropertySelection({
        ...graphColorPropertySelection,
        nodeColorSelection: {
          ...graphColorPropertySelection.nodeColorSelection,
          [newTargetNode]: "#6B96DA",
        },
        edgeColorSelection: [
          ...graphColorPropertySelection.edgeColorSelection,
          "#C7C7C7",
        ],
      });
    } else {
      setGraphColorPropertySelection({
        ...graphColorPropertySelection,
        edgeColorSelection: [
          ...graphColorPropertySelection.edgeColorSelection,
          "#C7C7C7",
        ],
      });
    }

    setTargetNodeCount(targetNodeCount + 1);

    // Update edge weights & & direction
    setCustomEdgeWeight((oldArray) => [...oldArray, 1]);
    setDirection((oldArray) => [...oldArray, "undirected"]);
    setEdgeLabelInput((oldArray) => [...oldArray, ""]);
    setEdgeLabelTypeSelection((oldArray) => [...oldArray, "custom-edge-label"]);

    // flatten targetNode arrays and concat new targetNode
    const flattenedTargetNodeArray = [].concat(
      ...Object.values(nodeConnections)
    );
    calcEdgeSize(
      flattenedTargetNodeArray.concat(newTargetNode),
      data,
      setNumEdges
    );
  };

  /**
   * Adds an additional source node and handles setting up states for new source node
   * @param {object} nodeConnections - object holding connected source nodes and target nodes
   */
  const handleAddSourceNode = (nodeConnections) => {
    const currentSourceNodes = Object.keys(nodeConnections);
    const availableSourceNodes = data.meta.fields.filter(
      (header) => !currentSourceNodes.includes(header)
    );

    // Error handling for user trying to add more source nodes when none exist
    if (availableSourceNodes.length < 1) {
      alert("No columns left to add");
      return;
    }

    const newSourceNode = availableSourceNodes[0];

    // let newTargetNode = sourceNodeProps.find((node) => node !== newSourceNode);
    let newTargetNode = Object.keys(nodeConnections).find(
      (node) => node !== newSourceNode
    );

    if (!newTargetNode) {
      newTargetNode = [].concat(...Object.values(nodeConnections))[0];
    }

    setNodeConnections((prevNodeConnections) => ({
      ...prevNodeConnections,
      [newSourceNode]: [newTargetNode],
    }));

    const updatedGraphColorSelection = { ...graphColorPropertySelection };

    // Check if source node header already has a color associated with it, if not assign default
    if (
      !Object.keys(updatedGraphColorSelection.nodeColorSelection).includes(
        newSourceNode
      )
    ) {
      updatedGraphColorSelection.nodeColorSelection[newSourceNode] = "#4CAF50";
    }

    // Check if target node header already has a color associated with it, if not assign default
    if (
      !Object.keys(updatedGraphColorSelection.nodeColorSelection).includes(
        newTargetNode
      )
    ) {
      updatedGraphColorSelection.nodeColorSelection[newTargetNode] = "#6B96DA";
    }

    // Add edge color
    updatedGraphColorSelection.edgeColorSelection.push("#C7C7C7");

    setGraphColorPropertySelection(updatedGraphColorSelection);

    setSourceNodeCount(sourceNodeCount + 1);

    // Update edge weights & & direction
    setCustomEdgeWeight((oldArray) => [...oldArray, 1]);
    setDirection((oldArray) => [...oldArray, "undirected"]);
    setEdgeLabelInput((oldArray) => [...oldArray, ""]);
    setEdgeLabelTypeSelection((oldArray) => [...oldArray, "custom-edge-label"]);

    // flatten targetNode arrays and concat new targetNode
    const flattenedTargetNodeArray = [].concat(
      ...Object.values(nodeConnections)
    );
    calcEdgeSize(
      flattenedTargetNodeArray.concat(newTargetNode),
      data,
      setNumEdges
    );
  };

  /**
   * Removes user selected target node and handle cleaning up states for removed target node
   *
   * @param {string} sourceNode - The selected source node
   */
  const handleRemoveSourceNode = (
    sourceNode,
    indexToRemove,
    nodeConnections,
    graphColorPropertySelection
  ) => {
    if (sourceNodeCount < 1) {
      return;
    }

    // for calculating edge size
    const nodeConnectionsExcludingSourceNodeToBeDeleted = {};
    for (const key in nodeConnections) {
      if (key !== sourceNode) {
        nodeConnectionsExcludingSourceNodeToBeDeleted[key] =
          nodeConnections[key];
      }
    }

    // for updating nodeConnections after removing sourceNode
    setNodeConnections((prevNodeConnections) => {
      const updatedNodeConnections = { ...prevNodeConnections };
      delete updatedNodeConnections[sourceNode]; // Delete the specified key

      return updatedNodeConnections;
    });

    setSourceNodeCount(sourceNodeCount - 1);

    // Determine which edge entries to remove
    let targetNodes = nodeConnections[sourceNode];
    let nodeCount = 0;
    let edgeIndicesToRemove = [];
    Object.keys(nodeConnections).forEach((node) => {
      if (node === sourceNode) {
        edgeIndicesToRemove = [nodeCount, nodeCount + targetNodes.length];
      } else {
        nodeCount += nodeConnections[node].length;
      }
    });

    const sliceComponent = (startSlice, endSlice, component) => {
      return component.slice(0, startSlice).concat(component.slice(endSlice));
    };

    const updatedNodeColors = {
      ...graphColorPropertySelection.nodeColorSelection,
    };
    // check if sourceNode header is used in targetNodes, if not, delete from nodeColors
    if (![].concat(...Object.values(nodeConnections)).includes(sourceNode)) {
      delete updatedNodeColors[sourceNode];
    }

    setGraphColorPropertySelection({
      ...graphColorPropertySelection,
      nodeColorSelection: updatedNodeColors,
      edgeColorSelection:
        edgeIndicesToRemove.length > 0
          ? sliceComponent(
              edgeIndicesToRemove[0],
              edgeIndicesToRemove[1],
              graphColorPropertySelection.edgeColorSelection
            )
          : graphColorPropertySelection.edgeColorSelection,
    });

    // Remove edge weight & direction at current index
    setCustomEdgeWeight(
      edgeIndicesToRemove.length > 0
        ? sliceComponent(
            edgeIndicesToRemove[0],
            edgeIndicesToRemove[1],
            customEdgeWeight
          )
        : customEdgeWeight
    );
    setDirection(
      edgeIndicesToRemove.length > 0
        ? sliceComponent(
            edgeIndicesToRemove[0],
            edgeIndicesToRemove[1],
            direction
          )
        : direction
    );
    setEdgeLabelInput(
      edgeIndicesToRemove.length > 0
        ? sliceComponent(
            edgeIndicesToRemove[0],
            edgeIndicesToRemove[1],
            edgeLabelInput
          )
        : edgeLabelInput
    );
    setEdgeLabelTypeSelection(
      edgeIndicesToRemove.length > 0
        ? sliceComponent(
            edgeIndicesToRemove[0],
            edgeIndicesToRemove[1],
            edgeLabelTypeSelection
          )
        : edgeLabelTypeSelection
    );
    calcEdgeSize(
      [].concat(
        ...Object.values(nodeConnectionsExcludingSourceNodeToBeDeleted)
      ),
      data,
      setNumEdges
    );
  };

  /**
   * Excludes an element from an array based on the provided index.
   *
   * @param {Array} array - The original array.
   * @param {number} indexToRemove - The index of the element to exclude.
   * @returns {Array} - A new array with the element at the specified index removed.
   */
  function excludeElementByIndex(array, indexToRemove) {
    return array.filter((_, index) => index !== indexToRemove);
  }

  /**
   * Removes user selected target node and handle cleaning up states for removed target node
   *
   * @param {string} targetNode - The selected target node
   * @param {string} sourceNode - The selected source node
   * @param {string} targetNodeIndex - The index of selected target node in relation to it's source node
   * @param {string} flattenedTargetNodeIndex - The index of selected target node in relation to all target nodes (absolute index)
   * @param {object} nodeConnections - object holding connected source nodes and target nodes
   * @param {function} setNodeConnections - The state setter function for nodeConnection properties.
   * @param {object} graphColorPropertySelection - object holding graph color properties
   * @param {function} setGraphColorPropertySelection - The state setter function for graph color properties
   */
  const handleRemoveTargetNode = (
    targetNode,
    sourceNode,
    targetNodeIndex,
    flattenedTargetNodeIndex,
    nodeConnections,
    setNodeConnections,
    graphColorPropertySelection,
    setGraphColorPropertySelection
  ) => {
    if (targetNodeCount < 1) {
      return;
    }

    const newTargetNodes = excludeElementByIndex(
      nodeConnections[sourceNode],
      targetNodeIndex
    );

    let updatedNodeColors = {
      ...graphColorPropertySelection.nodeColorSelection,
    };

    // check if sourceNode header is used in targetNodes, if not, delete from nodeColors
    if (
      !Object.keys(nodeConnections).includes(targetNode) &&
      ![].concat(...Object.values(nodeConnections)).includes(targetNode)
    ) {
      delete updatedNodeColors[targetNode];
    }

    setGraphColorPropertySelection({
      ...graphColorPropertySelection,
      nodeColorSelection: updatedNodeColors,
      edgeColorSelection: excludeElementByIndex(
        graphColorPropertySelection.edgeColorSelection,
        flattenedTargetNodeIndex
      ), //wrong -> fix
    });

    setNodeConnections((prevNodeConnections) => ({
      ...prevNodeConnections,
      [sourceNode]: newTargetNodes,
    }));
    setTargetNodeCount(targetNodeCount - 1);

    // Remove edge weight & direction at current index
    setCustomEdgeWeight(
      excludeElementByIndex(customEdgeWeight, flattenedTargetNodeIndex)
    );
    setDirection(excludeElementByIndex(direction, flattenedTargetNodeIndex));
    setEdgeLabelInput(
      excludeElementByIndex(edgeLabelInput, flattenedTargetNodeIndex)
    );
    setEdgeLabelTypeSelection(
      excludeElementByIndex(edgeLabelTypeSelection, flattenedTargetNodeIndex)
    );
    calcEdgeSize(newTargetNodes, data, setNumEdges);
  };

  /**
   * Select Component for choosing target nodes.
   *
   * @component
   * @param {string} sourceNode - The selected source node.
   * @param {number} index - The index of the target node.
   * @returns {JSX.Element} The rendered TargetNodeSelect component.
   */
  function TargetNodeSelect({
    sourceNode,
    index,
    nodeConnections,
    data,
    graphColorPropertySelection,
    setGraphColorPropertySelection,
  }) {
    const targetNodes = nodeConnections[sourceNode];
    const targetNodesWithoutCurrentIndex = targetNodes.filter(
      (_, i) => i !== index
    );
    const availableTargetNodes = data.meta.fields.filter(
      (header) =>
        header !== sourceNode &&
        !targetNodesWithoutCurrentIndex.includes(header)
    );

    return (
      <Select
        key={"data-summary-select-" + index}
        bg="white"
        onChange={(e) => {
          handleTargetNodeChange(
            e.target.value,
            targetNodes[index],
            targetNodes,
            sourceNode,
            nodeConnections,
            setNodeConnections,
            setNumEdges,
            index,
            data,
            graphColorPropertySelection,
            setGraphColorPropertySelection
          );
        }}
        fontSize={SELECT_FONTSIZE}
        defaultValue={targetNodes[index]}
      >
        {availableTargetNodes.map((header) => (
          <option
            key={header + "available-target-nodes-select-" + index}
            value={header}
          >
            {header}
          </option>
        ))}
      </Select>
    );
  }

  /**
   * Handles logic for checking/unchecking source node properties.
   *
   * @param {Array} checkboxArray - The array of checked checkboxes.
   * @param {Function} setSourceNodeProps - The state setter function for source node properties.
   * @param {Array} targetNodes - The array of target nodes.
   * @param {string} sourceNode - The selected source node.
   */
  function handleSourceNodePropCheckbox(
    checkboxArray,
    setSourceNodeProps,
    targetNodes,
    sourceNodes
  ) {
    // need to order by data.meta.fields names to stay consistent
    const newSourceNodeProps = data.meta.fields.filter((field) =>
      checkboxArray.includes(field)
    );
    setSourceNodeProps(newSourceNodeProps);
  }

  /**
   * Calculates the number of values for each node/property.
   *
   * @param {string|Array} node - The node or array of nodes to count values for.
   * @param {Array} data - The data array to analyze.
   * @returns {number} The total count of unique values.
   */
  function getNodeCount(node, data) {
    const nodes = Array.isArray(node) ? node : [node];
    const uniqueValues = new Set();
    let totalCount = 0;

    for (let i = 0, len = data.length; i < len; i++) {
      for (let j = 0, nlen = nodes.length; j < nlen; j++) {
        uniqueValues.add(data[i][nodes[j]]);
      }
    }

    totalCount = uniqueValues.size;

    return totalCount;
  }

  /**
   * Retrieves the first three unique entries for a given node from the data array.
   *
   * @param {string} node - The node to retrieve entries for.
   * @param {Array} data - The data array to analyze.
   * @returns {string} The comma-separated string of the first three entries.
   */
  function getFirstThreeEntries(node, data) {
    const uniqueNodes = new Set();
    data.forEach((item) => uniqueNodes.add(item[node]));
    return Array.from(uniqueNodes).slice(0, 3).join(", ");
  }

  /**
   * Toggles the select/unselect state for checkboxes of source node props.
   */
  const toggleSelectAll = () => {
    if (selectAll) {
      setSourceNodeProps([]);
    } else {
      // Only show/select column names that aren't already used in sourceNodes or targetNodes
      setSourceNodeProps(data.meta.fields);
    }
    setSelectAll(!selectAll);
  };

  return (
    <Modal
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setStep(step - 1);
      }}
    >
      <ModalOverlay bg="blackAlpha.400" />
      <ModalContent
        minW={{ base: "95vw", "2xl": "90vw" }}
        minH="80vh"
        borderRadius={12}
      >
        <ModalHeader>Data Summary</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={6} px={4}>
            {/* Start Source & Target Nodes */}
            <Box pl={2} fontWeight="bold" color="blue.500">
              {getNodeCount(Object.keys(nodeConnections), data.data) +
                getNodeCount(
                  [].concat(...Object.values(nodeConnections)),
                  data.data
                ) +
                numEdges}{" "}
              Total Elements
            </Box>
            <Box bg="#FFFFFF" borderWidth={2} p={6} borderRadius={12}>
              <Heading mb={4} size="md">
                Node Connections
              </Heading>
              <Stack direction="row" w="100%" spacing={0}>
                <NodeConnectionsStack
                  elementWidth="75%"
                  COLORWIDTH={COLORWIDTH}
                  data={data}
                  getFirstThreeEntries={getFirstThreeEntries}
                  getNodeCount={getNodeCount}
                  graphColorPropertySelection={graphColorPropertySelection}
                  handleAddSourceNode={handleAddSourceNode}
                  handleAddTargetNode={handleAddTargetNode}
                  handleRemoveSourceNode={handleRemoveSourceNode}
                  handleRemoveTargetNode={handleRemoveTargetNode}
                  handleSourceNodeChange={handleSourceNodeChange}
                  modalFileDelimiter={modalFileDelimiter}
                  NODEWIDTH={NODEWIDTH}
                  nodeConnections={nodeConnections}
                  PROPWIDTH={PROPWIDTH}
                  SELECT_FONTSIZE={SELECT_FONTSIZE}
                  setGraphColorPropertySelection={
                    setGraphColorPropertySelection
                  }
                  setModalFileDelimiter={setModalFileDelimiter}
                  setNodeConnections={setNodeConnections}
                  setNumEdges={setNumEdges}
                  sourceNodeCount={sourceNodeCount}
                  STATISTIC_FONTSIZE={STATISTIC_FONTSIZE}
                  targetNodeCount={targetNodeCount}
                  TargetNodeSelect={TargetNodeSelect}
                />
                <Box
                  bg={lightenHex("#6B96DA", 95)}
                  borderColor={lightenHex("#6B96DA", 50)}
                  borderRadius={12}
                  borderWidth={1}
                  py={6}
                  maxW="25%"
                  w="25%"
                >
                  <Box
                    w="100%"
                    px={8}
                    // overflowY="auto"
                    overflowX="hidden"
                    // maxH={`${Object.keys(nodeConnections).length * 16 + 15}vh`}
                  >
                    <Stack spacing={2} w={"100%"} direction="column">
                      <Text size="sm" w={"95%"}>
                        Properties{" "}
                        {
                          <Tooltip
                            label="Information to include when you click on a node."
                            placement="top"
                          >
                            <InfoIcon verticalAlign="center" fontSize="xs" />
                          </Tooltip>
                        }
                      </Text>
                      <Box w="100%">
                        <Divider
                          mb={2}
                          borderColor="gray.300"
                          borderWidth={1}
                        />
                      </Box>
                      <Button
                        w="100%"
                        colorScheme="blue"
                        onClick={toggleSelectAll}
                      >
                        {selectAll ? "Uncheck All" : "Check All"}
                      </Button>
                      <CheckboxGroup
                        onChange={(e) => {
                          handleSourceNodePropCheckbox(
                            e,
                            setSourceNodeProps,
                            [].concat(...Object.values(nodeConnections)),
                            Object.keys(nodeConnections)
                          );
                        }}
                        value={sourceNodeProps}
                        w="100%"
                        px={2}
                      >
                        {data.meta.fields.map((prop, index) => (
                          <Box w="100%" key={"source-node-box" + index}>
                            <Stack
                              w={"100%"}
                              spacing={3}
                              direction="row"
                              key={"stack-" + prop}
                            >
                              <Checkbox
                                isChecked={sourceNodeProps.includes(prop)}
                                value={prop}
                                key={"checkbox-" + prop}
                                size="lg"
                              />
                              <Box
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                fontSize={SELECT_FONTSIZE}
                                w="100%"
                                px={2}
                                borderWidth={1}
                                borderRadius={6}
                                py="5.5px"
                                // readOnly
                                key={"input-" + prop}
                              >
                                {prop}
                              </Box>
                            </Stack>
                            <Stack direction="row" display="flex">
                              <Text
                                ml="10"
                                mr="auto"
                                maxW="30%"
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textAlign="left"
                                fontSize={STATISTIC_FONTSIZE}
                              >
                                {getNodeCount(prop, data.data) + " "} Nodes
                              </Text>
                              <Text
                                ml="auto"
                                maxW="60%"
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textAlign="right"
                                fontSize={STATISTIC_FONTSIZE}
                              >
                                {getFirstThreeEntries(prop, data.data)}
                              </Text>
                            </Stack>
                          </Box>
                        ))}
                      </CheckboxGroup>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </Box>
            {/* Start Edges */}
            <Box bg="#FFFFFF" borderWidth={2} p={6} borderRadius={12}>
              <Heading mb={4} size="md">
                Edges
              </Heading>
              <Stack direction="column">
                <Stack
                  display="flex"
                  justify="space-between"
                  spacing={0}
                  direction="row"
                >
                  <Text size="sm" w={CONNECTED_NODES_WIDTH}>
                    Connected Nodes{" "}
                    {
                      <Tooltip
                        label="Defines the two node groups being connected"
                        placement="top"
                      >
                        <InfoIcon verticalAlign="center" fontSize="xs" />
                      </Tooltip>
                    }
                  </Text>
                  <Text size="sm" w={DIRECTION_WIDTH}>
                    Direction{" "}
                    {
                      <Tooltip
                        label="Undirected graphs indicate a two way relationship between nodes"
                        placement="top"
                      >
                        <InfoIcon verticalAlign="center" fontSize="xs" />
                      </Tooltip>
                    }
                  </Text>
                  <Text size="sm" textAlign="center" w={EDGE_COLOR_WIDTH}>
                    Color
                  </Text>
                  <Text size="sm" w="10%">
                    Edge Weight{" "}
                    {
                      <Tooltip
                        label="Defines the weight (size) of the edges connecting these nodes (defaults to 1)"
                        placement="top"
                      >
                        <InfoIcon verticalAlign="center" fontSize="xs" />
                      </Tooltip>
                    }
                  </Text>
                  <Text size="sm" w={EDGE_LABEL_WIDTH}>
                    Type of Edge Label{" "}
                    {
                      <Tooltip
                        label="Choose a user defined or column defined edge label"
                        placement="top"
                      >
                        <InfoIcon verticalAlign="center" fontSize="xs" />
                      </Tooltip>
                    }
                  </Text>
                  <Text size="sm" w={CUSTOM_EDGE_LABEL_WIDTH}>
                    Custom Edge Label{" "}
                    {
                      <Tooltip
                        label="Defines the label for the connecting lines between nodes"
                        placement="top"
                      >
                        <InfoIcon verticalAlign="center" fontSize="xs" />
                      </Tooltip>
                    }
                  </Text>
                </Stack>
                <Box w="100%" pb={2}>
                  <Divider borderColor="gray.300" borderWidth={1} />
                </Box>
                {[]
                  .concat(...Object.values(nodeConnections))
                  .map((targetNode, index) => {
                    let sourceNode;
                    let cumulativeCount = 0;

                    for (const [source, targets] of Object.entries(
                      nodeConnections
                    )) {
                      const targetCount = targets.length;

                      if (index < cumulativeCount + targetCount) {
                        // The targetNode at the specified index belongs to this sourceNode
                        sourceNode = source;
                        break;
                      }

                      cumulativeCount += targetCount;
                    }

                    if (!sourceNode) {
                      sourceNode = Object.keys(nodeConnections)[0];
                    }
                    return (
                      <EdgeModalComponent
                        w="100%"
                        key={"edge-modal-component" + index}
                        index={index}
                        dataFields={data.meta.fields}
                        sourceNode={sourceNode}
                        targetNode={targetNode}
                        graphColorPropertySelection={
                          graphColorPropertySelection
                        }
                        setGraphColorPropertySelection={
                          setGraphColorPropertySelection
                        }
                        SELECT_FONTSIZE={SELECT_FONTSIZE}
                        STATISTIC_FONTSIZE={STATISTIC_FONTSIZE}
                        setEdgeLabelInput={setEdgeLabelInput}
                        edgeLabelInput={edgeLabelInput}
                        numEdges={numEdges}
                        edgeWeight={customEdgeWeight}
                        edgeWeightError={edgeWeightError}
                        checkEdgeWeightValidity={checkEdgeWeightValidity}
                        direction={direction}
                        setDirection={setDirection}
                        edgeLabelTypeSelection={edgeLabelTypeSelection}
                        setEdgeLabelTypeSelection={setEdgeLabelTypeSelection}
                        PROPWIDTH={PROPWIDTH}
                        CONNECTED_NODES_WIDTH={CONNECTED_NODES_WIDTH}
                        DIRECTION_WIDTH={DIRECTION_WIDTH}
                        EDGE_COLOR_WIDTH={EDGE_COLOR_WIDTH}
                        EDGE_WEIGHT_WIDTH={EDGE_WEIGHT_WIDTH}
                        EDGE_LABEL_WIDTH={EDGE_LABEL_WIDTH}
                        CUSTOM_EDGE_LABEL_WIDTH={CUSTOM_EDGE_LABEL_WIDTH}
                      />
                    );
                  })}
              </Stack>
            </Box>
          </Stack>
        </ModalBody>
        {/* Start Modal Footer */}
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              setStep(step - 1);
              resetGraph();
            }}
          >
            Back
          </Button>
          <Button
            isDisabled={!file}
            colorScheme="blue"
            onClick={() => {
              handleSubmitData(
                Object.keys(nodeConnections), // all source nodes
                [].concat(...Object.values(nodeConnections)), // all target nodes
                nodeConnections,
                edgeLabelInput,
                edgeLabelTypeSelection,
                direction,
                sourceNodeProps,
                graphColorPropertySelection.nodeColorSelection,
                graphColorPropertySelection.edgeColorSelection,
                customEdgeWeight,
                modalFileDelimiter
              );
              onClose();
              setStep(step - 1);
            }}
          >
            Create Graph
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
