/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

// Load Sigma
import {
  useLoadGraph,
  useSetSettings,
  useRegisterEvents,
  useSigma,
} from "@react-sigma/core";
import drawLabel from "./label";
import drawHover from "./hover";

// Import the style file here
import "@react-sigma/core/lib/react-sigma.min.css";

// Load Graphology
import Graph from "graphology";
import circular from "graphology-layout/circular";
import random from "graphology-layout/random";
import forceAtlas2 from "graphology-layout-forceatlas2";
import louvain from "graphology-communities-louvain";
import { cropToLargestConnectedComponent } from "graphology-components";
import { useEffect, useState, useCallback, useMemo } from "react";
import iwanthue from "iwanthue";

/**
 * Creates the rendered graph component from the imported data
 * Highlights node/edges using reducers
 *
 * @param {object} filteredSelectedData - The filtered selected data used for rendering the graph.
 * @param {array} graphColorProperties - Object containing the colors for the graph.
 * @param {object} graphNodeProperties - Object representing the properties of graph nodes.
 * @param {object} graphEdgeProperties - Object representing the properties of graph edges.
 * @param {array} graphEvents - Object containing the events for the graph.
 * @param {function} setGraphEvents - Callback function to set the graph events.
 * @param {boolean} isOpen - Specifies whether the analysis drawer component is open or closed.
 * @param {function} onOpen - Callback function to handle the opening of the analysis drawer component.
 * @param {function} onClose - Callback function to handle the closing of the analysis drawer component.
 * @param {object} graphSettings - Object containing the settings of the graph.
 * @param {function} setGraphSettings - Callback function to set the graph settings.
 * @param {number} userDefinedNodeSizeMin - The smallest possible node size.
 * @param {number} userDefinedNodeSizeMax - The largest possible node size.
 */

export default function CreateGraph({
  filteredSelectedData,
  graphColorProperties,
  graphNodeProperties,
  graphEdgeProperties,
  graphEvents,
  setGraphEvents,
  isOpen,
  onOpen,
  onClose,
  graphSettings,
  setGraphSettings,
  userDefinedNodeSizeMax,
  userDefinedNodeSizeMin,
}) {
  const graph = new Graph();
  const sigma = useSigma({
    edgeLabelSize: "proportional",
  });
  const labelRenderedSizeThreshold = graphSettings.forceAnnotations ? 0 : 6;
  const loadGraph = useLoadGraph();
  const registerEvents = useRegisterEvents();
  const setSettings = useSetSettings();
  const [draggedNode, setDraggedNode] = useState(null);
  let targetNodeIndex = 0;

  function moveNodeToEnd(graph, node) {
    // Move node to end of node array so that it gets rendered into the canvas last
    const nodeToRemove = graph._nodes.get(node);
    if (nodeToRemove) {
      graph._nodes.delete(node);
      graph._nodes.set(node, nodeToRemove);
    }
  }

  // Calculate all neighboring nodes for quicker neighbor lookups in renderers
  function getNodeNeighborSets(graph) {
    const neighborSets = {};
    graph.nodes().forEach((nodeId) => {
      neighborSets[nodeId] = new Set(graph.neighbors(nodeId));
    });
    return neighborSets;
  }

  useEffect(() => {
    if (filteredSelectedData) {
      console.log("Rendering graph..");

      // 2. Loop through node connections to build the bipartite graph
      Object.keys(graphNodeProperties.nodeConnections).forEach(
        (sourceNodeHeader, sourceNodeHeaderIndex) => {
          filteredSelectedData.data.forEach((line) => {
            if (typeof line !== "undefined") {
              if (line[sourceNodeHeader]) {
                // ensure line data is in string format
                let currentSourceNodeLine = line[sourceNodeHeader]
                  .toString()
                  .trim();
                let sourceNodeArray = [];

                // Check if user has selected a delimiter and has multiple entries separated by , or ;
                if (graphNodeProperties.fileDelimiter) {
                  // delimit by comma ","
                  if (graphNodeProperties.fileDelimiter === ",") {
                    sourceNodeArray = currentSourceNodeLine
                      .replace(/,\s*$/, "")
                      .split(",");
                  }
                  // delimit by semi-colon ";"
                  else {
                    sourceNodeArray = currentSourceNodeLine
                      .replace(/,\s*$/, "")
                      .split(";");
                  }
                }
                // no delimiter selected
                else {
                  sourceNodeArray = Array.of(currentSourceNodeLine);
                }

                sourceNodeArray.forEach((sourceNode) => {
                  sourceNode = sourceNode.trim();
                  // Create the source nodes:
                  if (!graph.hasNode(sourceNode))
                    graph.addNode(sourceNode, {
                      nodeType: "source",
                      label: sourceNode,
                      // color: graphColors.sourceNodeColor[sourceNodeHeaderIndex],
                      color: graphColorProperties.nodeColors[sourceNodeHeader],
                      header: sourceNodeHeader,
                    });
                  // Check if source node was created as a target node and update label
                  else if (
                    graph.getNodeAttribute(sourceNode, "nodeType") === "target"
                  ) {
                    graph.setNodeAttribute(sourceNode, "nodeType", "source");
                    // graph.setNodeAttribute(sourceNode, "color", graphColors.sourceNodeColor[sourceNodeHeaderIndex]);
                    graph.setNodeAttribute(
                      sourceNode,
                      "color",
                      graphColorProperties.nodeColors[sourceNodeHeader]
                    );
                    graph.setNodeAttribute(
                      sourceNode,
                      "header",
                      sourceNodeHeader
                    );
                  }

                  // graphNodeProperties.targetNodeHeader.forEach((selectedTargetNode, index) => {
                  graphNodeProperties.nodeConnections[sourceNodeHeader].forEach(
                    (selectedTargetNode, index) => {
                      let currentIndex =
                        sourceNodeHeaderIndex === 0
                          ? index
                          : targetNodeIndex + index;
                      // Add target node and edge
                      if (line[selectedTargetNode]) {
                        // ensure line data is in string format
                        let currentTargetNodeLine = line[selectedTargetNode]
                          .toString()
                          .trim();
                        let targetNodeArray = [];

                        // Check if user has selected a delimiter and has multiple entries separated by , or ;
                        if (graphNodeProperties.fileDelimiter) {
                          // delimit by comma ","
                          if (graphNodeProperties.fileDelimiter === ",") {
                            targetNodeArray = currentTargetNodeLine
                              .replace(/,\s*$/, "")
                              .split(",");
                          }
                          // delimit by semi-colon ";"
                          else {
                            targetNodeArray = currentTargetNodeLine
                              .replace(/,\s*$/, "")
                              .split(";");
                          }
                        }
                        // no delimiter selected
                        else {
                          targetNodeArray = Array.of(currentTargetNodeLine);
                        }

                        // Create target node and add edge if not created already
                        targetNodeArray.forEach((targetNode) => {
                          targetNode = targetNode.trim();
                          if (
                            targetNode &&
                            targetNode !== "NA" &&
                            targetNode != null
                          ) {
                            if (!graph.hasNode(targetNode)) {
                              graph.addNode(targetNode, {
                                nodeType: "target",
                                label: targetNode,
                                color:
                                  graphColorProperties.nodeColors[
                                    selectedTargetNode
                                  ],
                                header: selectedTargetNode,
                              });
                            }
                            if (!graph.hasEdge(sourceNode, targetNode)) {
                              // Add arrow to edge if graph type is directed
                              if (
                                graphEdgeProperties.graphDirection[
                                  currentIndex
                                ] === "directed"
                              ) {
                                if (
                                  graphEdgeProperties.edgeLabelType[
                                    currentIndex
                                  ] === "custom-edge-label"
                                ) {
                                  graph.addEdge(sourceNode, targetNode, {
                                    label:
                                      graphEdgeProperties.edgeLabel[
                                        currentIndex
                                      ],
                                    color:
                                      graphColorProperties.edgeColors[
                                        currentIndex
                                      ],
                                    type: "arrow",
                                    size: Math.max(
                                      graphEdgeProperties.edgeWeight[
                                        currentIndex
                                      ],
                                      1
                                    ),
                                  });
                                } else {
                                  graph.addEdge(sourceNode, targetNode, {
                                    label:
                                      line[
                                        graphEdgeProperties.edgeLabel[
                                          currentIndex
                                        ]
                                      ],
                                    color:
                                      graphColorProperties.edgeColors[
                                        currentIndex
                                      ],
                                    type: "arrow",
                                    size: Math.max(
                                      graphEdgeProperties.edgeWeight[
                                        currentIndex
                                      ],
                                      1
                                    ),
                                  });
                                }
                              } else {
                                if (
                                  graphEdgeProperties.edgeLabelType[
                                    currentIndex
                                  ] === "custom-edge-label"
                                ) {
                                  graph.addEdge(sourceNode, targetNode, {
                                    label:
                                      graphEdgeProperties.edgeLabel[
                                        currentIndex
                                      ],
                                    color:
                                      graphColorProperties.edgeColors[
                                        currentIndex
                                      ],
                                    size: graphEdgeProperties.edgeWeight[
                                      currentIndex
                                    ],
                                  });
                                } else {
                                  graph.addEdge(sourceNode, targetNode, {
                                    label:
                                      line[
                                        graphEdgeProperties.edgeLabel[
                                          currentIndex
                                        ]
                                      ],
                                    color:
                                      graphColorProperties.edgeColors[
                                        currentIndex
                                      ],
                                    size: graphEdgeProperties.edgeWeight[
                                      currentIndex
                                    ],
                                  });
                                }
                              }
                            }
                          }
                        });
                      }
                    }
                  );
                });
              }
            }
          });
          targetNodeIndex =
            targetNodeIndex +
            graphNodeProperties.nodeConnections[sourceNodeHeader].length;
        }
      );

      // 3. Only keep the main connected component: (optional)
      if (graphSettings.cropGraph) {
        cropToLargestConnectedComponent(graph);
      }

      // 4. Create colors array based on node communities from Louvain
      louvain.assign(graph);
      const louvainCommunities = louvain.detailed(graph);
      const palette = iwanthue(louvainCommunities.count, { seed: "graph" });
      const nodeColors = [];
      for (let i = 0; i <= louvainCommunities.count; i++) {
        nodeColors.push(palette.pop());
      }

      // 5. Use degrees for node sizes:
      const degrees = graph.nodes().map((node) => graph.degree(node));
      const minDegree = Math.min(...degrees);
      const maxDegree = Math.max(...degrees);
      if (!userDefinedNodeSizeMax || !userDefinedNodeSizeMin) {
        userDefinedNodeSizeMin = graph.order > 1000 ? 4 : 6;
        userDefinedNodeSizeMax = graph.order > 1000 ? 16 : 25;
      }

      graph.forEachNode((node, attributes) => {
        const degree = graph.degree(node);

        // set node color by community if user selects "groups" button
        if (graphSettings.colorByCommunity) {
          const nodeCommunityIndex = louvainCommunities.communities[node];
          attributes.color = nodeColors[nodeCommunityIndex];
        }

        attributes.size =
          userDefinedNodeSizeMin +
          ((degree - minDegree) / (maxDegree - minDegree)) *
            (userDefinedNodeSizeMax - userDefinedNodeSizeMin);
      });

      // 6. Position nodes on a circle, then run Force Atlas 2 for a while to get  proper graph layout
      if (graphSettings.isCircular) {
        circular.assign(graph);
      } else {
        // Set initial layout to random so all nodes have a position before running FA2
        random.assign(graph);
        const settings = forceAtlas2.inferSettings(graph);
        settings["scalingRatio"] = 280;
        if (graph.order > 1000) {
          forceAtlas2.assign(graph, {
            settings,
            iterations: 110,
            worker: true,
          });
        } else if (graph.order > 250) {
          forceAtlas2.assign(graph, {
            settings,
            iterations: 220,
            worker: true,
          });
        } else {
          settings["scalingRatio"] = 10;
          forceAtlas2.assign(graph, {
            settings,
            iterations: 400,
            worker: false,
          });
        }
      }

      // render graph, save data and graph states
      setSettings({
        labelSize: 18,
        labelRenderedSizeThreshold: labelRenderedSizeThreshold,
      });
      loadGraph(graph);
      setGraphSettings({
        ...graphSettings,
        sigmaInstance: sigma,
        graphData: graph,
      });

      // Testing purposes
      // console.log('Edge Size:', graph['_undirectedSize'])
      console.log("Finished rendering!");
    }
  }, [
    graphColorProperties.nodeColors,
    graphColorProperties.edgeColors,
    graphEdgeProperties.edgeLabel,
    graphEdgeProperties.edgeWeight,
    graphEdgeProperties.graphDirection,
    filteredSelectedData,
    graphNodeProperties.nodeConnections,
    graphNodeProperties.fileDelimiter,
    graphSettings.isCircular,
    graphSettings.colorByCommunity,
    graphSettings.cropGraph,
    graphSettings.forceAnnotations,
  ]);

  // For registering events within graph canvas
  useEffect(() => {
    let didNodeMove = false;

    // Register the events
    registerEvents({
      clickStage: () => {
        setGraphEvents({
          hoveredNode: null,
          clickedNode: null,
        });
        setDraggedNode(null);
        didNodeMove = false;
        // show/hide drawer
        if (isOpen) onClose();
      },
      clickNode: (e) => {
        if (didNodeMove) {
          didNodeMove = false;
        } else {
          setTimeout(() => {
            setDraggedNode(null);
            setGraphEvents({
              ...graphEvents,
              clickedNode: e.node,
            });
            // Move camera to clicked node only when user clicks inside drawer
            const x = sigma["nodeDataCache"][e.node].x;
            const y = sigma["nodeDataCache"][e.node].y;
            sigma
              .getCamera()
              .animate({ x, y }, { easing: "linear", duration: 500 });
            if (!isOpen) {
              onOpen();
            }
          }, 150);
        }
      },
      enterNode: (e) => {
        setGraphEvents({
          ...graphEvents,
          hoveredNode: e.node,
        });
      },
      leaveNode: () => {
        if (!draggedNode) {
          setGraphEvents({
            ...graphEvents,
            hoveredNode: null,
          });
        }
      },
      downNode: (e) => {
        setDraggedNode(e.node);
        sigma.getGraph().setNodeAttribute(e.node, "highlighted", true);
      },
      mouseup: (e) => {
        if (draggedNode) {
          setDraggedNode(null);
          sigma.getGraph().removeNodeAttribute(draggedNode, "highlighted");
        }
      },
      mousedown: (e) => {
        // Disable the autoscale at the first down interaction
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
      mousemove: (e) => {
        if (draggedNode) {
          didNodeMove = true;
          // Get new position of node
          const pos = sigma.viewportToGraph(e);
          sigma.getGraph().setNodeAttribute(draggedNode, "x", pos.x);
          sigma.getGraph().setNodeAttribute(draggedNode, "y", pos.y);

          // Prevent sigma to move camera:
          e.preventSigmaDefault();
          e.original.preventDefault();
          e.original.stopPropagation();
        }
      },
      touchup: (e) => {
        if (draggedNode) {
          setDraggedNode(null);
          sigma.getGraph().removeNodeAttribute(draggedNode, "highlighted");
        }
      },
      touchdown: (e) => {
        // Disable the autoscale at the first down interaction
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
      touchmove: (e) => {
        if (draggedNode) {
          didNodeMove = true;
          // Get new position of node
          const pos = sigma.viewportToGraph(e);
          sigma.getGraph().setNodeAttribute(draggedNode, "x", pos.x);
          sigma.getGraph().setNodeAttribute(draggedNode, "y", pos.y);

          // Prevent sigma to move camera:
          e.preventSigmaDefault();
          e.original.preventDefault();
          e.original.stopPropagation();
        }
      },
    });
  }, [graphEvents.clickedNode, draggedNode, isOpen]);

  // Get updated graph to pass to reducers
  const updatedGraph = useMemo(() => sigma.getGraph(), [filteredSelectedData]);

  // get all neighbor sets to speed up hovered neighbor search
  const nodeNeighborSets = getNodeNeighborSets(updatedGraph);

  const nodeReducer = useCallback(
    (node, data) => {
      const newData = { ...data, highlighted: data.highlighted || false };

      // Both clickedNode and hoveredNode
      if (graphEvents.hoveredNode && graphEvents.clickedNode) {
        if (
          node === graphEvents.hoveredNode ||
          node === graphEvents.clickedNode ||
          nodeNeighborSets[graphEvents.hoveredNode].has(node) ||
          nodeNeighborSets[graphEvents.clickedNode].has(node)
        ) {
          newData.highlighted = true;
          newData.sourceHeader = Object.keys(
            graphNodeProperties.nodeConnections
          );
          newData.targetHeader = [].concat(
            ...Object.values(graphNodeProperties.nodeConnections)
          );
          newData.hovered = false;
          if (
            node === graphEvents.hoveredNode ||
            node === graphEvents.clickedNode
          ) {
            moveNodeToEnd(updatedGraph, node);
            newData.hovered = true;
          }
        } else {
          newData.color = "#E2E2E2";
          newData.highlighted = false;
        }
      }

      // Only hoveredNode
      else if (graphEvents.hoveredNode) {
        if (
          node === graphEvents.hoveredNode ||
          nodeNeighborSets[graphEvents.hoveredNode].has(node)
        ) {
          newData.highlighted = true;
          newData.sourceHeader = Object.keys(
            graphNodeProperties.nodeConnections
          );
          newData.targetHeader = [].concat(
            ...Object.values(graphNodeProperties.nodeConnections)
          );
          newData.hovered = false;
          if (node === graphEvents.hoveredNode) {
            moveNodeToEnd(updatedGraph, node);
            newData.hovered = true;
          }
        } else {
          newData.color = "#E2E2E2";
          newData.highlighted = false;
        }
      }

      // Only clickedNode
      else if (graphEvents.clickedNode) {
        if (
          node === graphEvents.clickedNode ||
          nodeNeighborSets[graphEvents.clickedNode].has(node)
        ) {
          newData.highlighted = true;
          newData.sourceHeader = Object.keys(
            graphNodeProperties.nodeConnections
          );
          newData.targetHeader = [].concat(
            ...Object.values(graphNodeProperties.nodeConnections)
          );
          newData.hovered = false;
          if (node === graphEvents.clickedNode) {
            moveNodeToEnd(updatedGraph, node);
            newData.hovered = true;
          }
        } else {
          newData.color = "#E2E2E2";
          newData.highlighted = false;
        }
      }
      return newData;
    },
    [
      graphEvents.hoveredNode,
      graphEvents.clickedNode,
      graphNodeProperties.nodeConnections,
      moveNodeToEnd,
      nodeNeighborSets,
    ]
  );

  const edgeReducer = useCallback(
    (edge, data) => {
      const graph = updatedGraph;

      // Check for bi-directional labels if user has defined a data column to use for labels
      if (graphEdgeProperties.edgeLabelType.includes("column-edge-label")) {
        // Get source node and target node
        const [source, target] = graph.extremities(edge);
        // Check for bi-directional relationship
        const edgeOne = graph.edge(source, target);
        const edgeTwo = graph.edge(target, source);
        if (edgeOne && edgeTwo) {
          // edge is bi-directional, check for two labels
          const edgeOneLabel = graph.getEdgeAttribute(edgeOne, "label");
          const edgeTwoLabel = graph.getEdgeAttribute(edgeTwo, "label");

          if (edgeOneLabel && edgeTwoLabel && edgeOneLabel !== edgeTwoLabel) {
            // Create new combined label so there are no overlaps
            const combinedLabel =
              edgeOneLabel + ` (${source}) | ` + edgeTwoLabel + ` (${target})`;
            // Set first edge to new label and set second edge to null
            graph.setEdgeAttribute(edgeOne, "label", combinedLabel);
            graph.setEdgeAttribute(edgeTwo, "label", null);
          }
        }
      }

      const newData = { ...data, hidden: false };

      // Show both hoveredNode and clickedNode labels
      if (graphEvents.hoveredNode && graphEvents.clickedNode) {
        if (
          !graph.extremities(edge).includes(graphEvents.hoveredNode) &&
          !graph.extremities(edge).includes(graphEvents.clickedNode)
        ) {
          newData.hidden = true;
          sigma.setSetting("renderEdgeLabels", true);
        }
      }

      // show only hoveredNode labels
      else if (
        graphEvents.hoveredNode &&
        !graph.extremities(edge).includes(graphEvents.hoveredNode)
      ) {
        newData.hidden = true;
        sigma.setSetting("renderEdgeLabels", true);
      }

      // show only clickedNode labels
      else if (
        graphEvents.clickedNode &&
        !graph.extremities(edge).includes(graphEvents.clickedNode)
      ) {
        newData.hidden = true;
        sigma.setSetting("renderEdgeLabels", true);
      }

      // Otherwise, show no labels
      else {
        // hide edge labels
        sigma.setSetting("renderEdgeLabels", false);
      }
      return newData;
    },
    [
      graphEvents.hoveredNode,
      graphEvents.clickedNode,
      graphNodeProperties.nodeConnections,
      moveNodeToEnd,
    ]
  );

  // Highlight node and edges on node hover and node click
  useEffect(() => {
    setSettings({
      nodeReducer: nodeReducer,
      edgeReducer: edgeReducer,

      // Modify label rendering
      labelRenderedSizeThreshold: labelRenderedSizeThreshold,
      labelSize: 18,
      zIndex: true,
      labelRenderer: drawLabel,
      hoverRenderer: drawHover,
    });
  }, [graphEvents.hoveredNode, graphEvents.clickedNode, filteredSelectedData]);

  return null;
}
