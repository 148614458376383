import React from "react";
import "./portfolio.css";
import ReverseHorizontalCard from "../Components/reverseHorizontalCard";
import HorizontalCard from "../Components/horizontalCard";

/* IMAGES */
import crypto_cross from "../images/crypto_cross.png"
import blockchain from "../images/blockchain.jpg";
import sorting_visualizer from "../images/sorting-visualizer.png";
import flappyBirds from "../images/flappy_birds.png";
import cartpole from "../images/moab_post_training.png";
import kaggle_house from "../images/kaggle_housing.jpg";
import stock_scraper from "../images/stock_scraper.jpg";
import react_calc from "../images/react_calculator.png";
import website from "../images/website.png";
import scratch from "../images/scratch.png";
import graph from "../images/graph-visual.png"
import predictatrailer from "../images/BAT.png"

export default function Portfolio() {
  return (
    <>
      <div className="portfolio-card-container">
      <HorizontalCard
          path={{
            pathname: "https://predictatrailer.com",
          }}
          src={predictatrailer}
          href="https://predictatrailer.com"
          alt="predictatrailer.com - uses machine learning to predict car auctions"
          title="PredictATrailer.com"
          text="I created a web application that uses a machine learning model trained on 
          historical car auction results to predict the results of any BringATrailer.com auction
          results."
        />
        <ReverseHorizontalCard
          path= "/graph"
          src={graph}
          href="/graph"
          alt="Upload and Visualize Graph Network Data"
          title="Upload/Visualize Graph Network Data"
          text="I created a web application that allows users to upload .CSV data and
          visualize that data within a graph network."
        />
      <HorizontalCard
          path= "/crypto"
          src={crypto_cross}
          href="/crypto"
          alt="Crypto stock charts with buy/sell indicators and profit margins"
          title="Cryptocurrency Charts w/ Technical Analysis & Profit Margins"
          text="Check out my dynamic cryptocurrency charts with Buy/Sell
          indicators, double exponential moving averages, profit margins, etc."
        />
      <ReverseHorizontalCard
          path={{
            pathname: "https://github.com/Tropskee/Blockchain",
          }}
          src={blockchain}
          href="https://github.com/Tropskee/Blockchain"
          alt="Create a small blockchain using Python"
          title="Creating a Small Blockchain using Python"
          text="Understand by doing! Learn how Blockchains (like Bitcoin and Ethereum) work
          by creating your own using Python and Flask."
        />
      <HorizontalCard
          path= "/sorting-visualizer"
          src={sorting_visualizer}
          href="/sorting-visualizer"
          alt="Sorting algorithm visualizer tool built in React"
          title="Sorting Algorithm Visualizer built in React"
          text="Check out my sorting algorithm visualizer with animation time scaling. Quick sort, merge sort, heap sort, 
          bubble sort, etc! *Not optimized for mobile phones*"
        />
        <ReverseHorizontalCard
          path={{
            pathname: "https://github.com/Tropskee/React-Personal-Website",
          }}
          src={website}
          href="https://github.com/Tropskee/React-Personal-Website"
          alt="My personal website designed in React"
          title="My Personal Website Built in React"
          text="All of the code that this website is built upon! HTML, CSS, Javscript,
          React, etc!"
        />
        <HorizontalCard
          path={{ pathname: "https://github.com/Tropskee/NeatFlappyBird" }}
          src={flappyBirds}
          href="https://github.com/Tropskee/NeatFlappyBird"
          alt="Reinforcement Learning via Flappy Birds"
          title="Flappy Birds Reinforcement Learning"
          text="I employ reinforcement learning to beat the classic game
          of Flappy Birds using the NEAT (NeuroEvolution of Augmenting Topologies) algorithm."
        />
        <ReverseHorizontalCard
          path={{ pathname: "https://github.com/Tropskee/NEAT_CartPole" }}
          src={cartpole}
          href="https://github.com/Tropskee/NEAT_CartPole"
          alt="Reinforcement Learning via CartPole and OpenAI"
          title="CartPole Reinforcement Learning"
          text="I employ reinforcement learning to beat openAI's
          CartPole game using the NEAT (NeuroEvolution of Augmenting Topologies) algorithm."
        />
        <HorizontalCard
          path={{
            pathname: "https://serverless-scratch.netlify.app/",
          }}
          src={scratch}
          href="https://serverless-scratch.netlify.app/"
          alt="Scratch: A Simple Note Taking App"
          title="Scratch: A Simple Note Taking App"
          text="I created a simple note taking app using the serverless stack framework and hosted in the cloud through AWS."
        />
        <ReverseHorizontalCard
          path="/calculator"
          src={react_calc}
          alt="Creating A Calculator in React"
          href="/calculator"
          title="Creating A Calculator in React"
          text="I created a fully functional React/Javascript calculator (which takes a little
            more logic than you might imagine!)."
        />
        <HorizontalCard
          path={{
            pathname:
              "https://github.com/Tropskee/Kaggle-Housing-Price-ML-Model",
          }}
          src={kaggle_house}
          href="https://github.com/Tropskee/Kaggle-Housing-Price-ML-Model"
          alt="Kaggle Machine Learning House Price Predictor"
          title="Machine Learning House Price Predictor"
          text="I created a machine learning model that I used to get into top 1% in the
          housing price competition on Kaggle."
        />
        <ReverseHorizontalCard
          path={{ pathname: "https://github.com/Tropskee/Quick-Stock-Scraper" }}
          src={stock_scraper}
          href="https://github.com/Tropskee/Quick-Stock-Scraper"
          alt="Python Quick Stock Scraping Script"
          title="Quick + Easy Python Stock Scraper"
          text="Very simple stock scraper that outputs the current stock price in
          real time. Simply change the stock ticker in the code and you can stay up
          to date with how the stock is performing that day."
        />
      </div>
    </>
  );
}
