import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import Papa from "papaparse";
import readExcelFile from "../Utils/readExcelFile";
import DataImportModal from "./DataImportModal";
import DataSummaryModal from "./DataSummaryModal";

/**
 * Represents a two-step data import model.
 * Step 1: import user selected csv/excel dataset
 * Step 2: create data summary and selectable graph parameters
 *
 * @param {boolean} isOpen - Specifies whether the modal is open or closed.
 * @param {function} onClose - Callback function to handle the closing of the modal.
 * @param {function} setSelectedData - Callback function to set the selected data.
 * @param {function} setFilteredSelectedData - Callback function to set the filtered selected data.
 * @param {object} graphNodeProperties - Object representing the properties of graph nodes.
 * @param {function} setGraphNodeProperties - Callback function to set the graph node properties.
 * @param {array} graphColors - Array containing the colors for the graph.
 * @param {function} setGraphColors - Callback function to set the graph colors.
 * @param {object} graphEdgeProperties - Object representing the properties of graph edges.
 * @param {function} setGraphEdgeProperties - Callback function to set the graph edge properties.
 * @param {array} graphEvents - Array containing the events for the graph.
 * @param {function} setGraphEvents - Callback function to set the graph events.
 * @param {number} step - Current step of the data import process.
 * @param {function} setStep - Callback function to set the current step.
 * @param {function} resetGraph - Callback function to reset the graph.
 */

function MultiStepDataImportModal({
  isOpen,
  onClose,
  setSelectedData,
  setFilteredSelectedData,
  setFilteredExportData,
  graphNodeProperties,
  setGraphNodeProperties,
  graphColorProperties,
  setGraphColorProperties,
  graphEdgeProperties,
  setGraphEdgeProperties,
  graphEvents,
  setGraphEvents,
  step,
  setStep,
  resetGraph,
}) {
  // Allow file input boxes to be hidden
  let hiddenInputCsv,
    hiddenInputExcel = null;
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [data, setData] = useState(null);
  const toast = useToast();

  // Local States
  const [initialNumEdges, setInitialNumEdges] = useState(null);
  const [nodeConnectionsSelection, setNodeConnectionsSelection] = useState(
    graphNodeProperties.nodeConnections
  );

  // Data Summary Modal Display Formats
  const NODEWIDTH = "100%";
  const COLORWIDTH = "100%";
  const PROPWIDTH = "100%";
  const SELECT_FONTSIZE = "lg";
  const STATISTIC_FONTSIZE = "12px";

  // Parsing uploaded file
  function handleDataUpload(file) {
    // reset graph
    resetGraph();

    const validFileTypes = ["csv", "xlsx"];

    // Get file type
    const fileName = file.name;
    let lastDot = fileName.lastIndexOf(".");
    const fileExtension = fileName.substring(lastDot + 1).toLowerCase();

    if (!validFileTypes.includes(fileExtension)) {
      alert(
        "Invalid file type. Please upload a file with a valid format and extension."
      );
      return;
    }
    // Check file extension and handle upload
    if (fileExtension === "csv") {
      try {
        Papa.parse(file, {
          download: true,
          header: true,
          worker: true,
          delimiter: ",",
          complete: (results) => {
            var dataColumns = results.meta.fields;
            dataColumns = removeDuplicates(dataColumns);
            setNodeConnectionsSelection({ [dataColumns[0]]: [dataColumns[1]] });
            setGraphColorProperties({
              ...graphColorProperties,
              nodeColors: {
                [dataColumns[0]]: "#4CAF50",
                [dataColumns[1]]: "#6B96DA",
              },
            });
            setGraphNodeProperties({
              ...graphNodeProperties,
              sourceNodePropsSelection: dataColumns,
            });

            setData(results);
            calcEdgeSize(dataColumns[1], results, setInitialNumEdges);
            setStep(step + 1);
          },
          error: (error) => {
            alert(
              "There was an error with the imported file, please check your file and try again."
            );
            console.log("Error:", error);
          },
        });
      } catch (error) {
        alert(
          "There was an error with the imported file, please check your file and try again."
        );
        console.log("Error:", error);
      }
    } else if (fileExtension === "xlsx") {
      async function fetchExcel() {
        try {
          // Create result dict that matches .csv import structure
          let resultDict = { meta: { fields: [] }, data: [] };
          let dataDict = [];
          const result = await readExcelFile(file);
          var dataColumns = result[0];
          dataColumns = removeDuplicates(dataColumns);
          // remove header row from result
          result.shift();
          setNodeConnectionsSelection({ [dataColumns[0]]: [dataColumns[1]] });
          setGraphColorProperties({
            ...graphColorProperties,
            nodeColors: {
              [dataColumns[0]]: "#4CAF50",
              [dataColumns[1]]: "#6B96DA",
            },
          });
          setGraphNodeProperties({
            ...graphNodeProperties,
            sourceNodePropsSelection: dataColumns,
          });

          // Create a data dictionary to match .csv "data" structure
          result.forEach((line) => {
            let lineDict = {};
            line.map((val, index) => (lineDict[dataColumns[index]] = val));
            dataDict.push(lineDict);
          });
          resultDict["meta"]["fields"] = dataColumns;
          resultDict["data"] = dataDict;

          setData(resultDict);
          calcEdgeSize(dataColumns[1], resultDict, setInitialNumEdges);
          setStep(step + 1);
        } catch (error) {
          alert(
            "There was an error with the imported file, please check your file and try again."
          );
          console.error(error);
        }
      }
      fetchExcel();
    }
  }

  function removeDuplicates(arr) {
    return Array.from(new Set(arr));
  }

  /**
   * Splits a string by a given delimiter and returns the count of elements.
   *
   * @param {string} str - The string to be split and counted.
   * @param {string} delimiter - The delimiter used to split the string.
   * @returns {number} The count of elements after splitting the string.
   */
  function delimitAndCountElements(str, delimiter) {
    return str.split(delimiter).length;
  }

  // Calculate number of edges
  function calcEdgeSize(dataHeader, data, setNumEdges) {
    // console.log(dataHeader, data)
    let totalEdgeSize = 0;

    // handle case where node data structure is array
    if (Array.isArray(dataHeader)) {
      for (let i = 0; i < dataHeader.length; i++) {
        let count = 0;

        data.data.forEach((line) => {
          // ensure line data is in string format
          const fieldValue =
            line[dataHeader[i]] === undefined
              ? null
              : line[dataHeader[i]].toString();
          if (fieldValue) {
            const fieldValueArr = fieldValue.split(",");
            const fieldValueSemiColonArr = fieldValue.split(";");

            if (fieldValueArr.length > 1) {
              count += fieldValueArr.length;
            } else if (fieldValueSemiColonArr.length > 1) {
              count += fieldValueSemiColonArr.length;
            } else {
              count += 1;
            }
          }
        });

        totalEdgeSize += count;
      }
    }
    // handle case where node data structure is string
    else {
      data.data.forEach((line) => {
        if (line[dataHeader]) {
          const lineString = line[dataHeader].toString();
          if (delimitAndCountElements(lineString, ",") > 1) {
            totalEdgeSize += delimitAndCountElements(lineString, ",");
          } else if (delimitAndCountElements(lineString, ";") > 1) {
            totalEdgeSize += delimitAndCountElements(lineString, ";");
          } else {
            totalEdgeSize += 1;
          }
        }
      });
    }

    setNumEdges(totalEdgeSize);
  }

  // Final submit of data used to build graph
  const handleSubmitData = (
    sourceNodes,
    targetNodes,
    nodeConnections,
    edgeLabelInput,
    edgeLabelTypeSelection,
    direction,
    sourceNodeProps,
    nodeColorPropertySelection,
    edgeColorPropertySelection,
    customEdgeWeight,
    modalFileDelimiter
  ) => {
    // reset graph
    resetGraph();
    setSelectedData(null);
    setFilteredSelectedData(null);

    // Check edge weights for "" values
    let validatedEdgeWeights = customEdgeWeight.map((weight) => {
      if (weight === "") {
        return 1;
      } else {
        return weight;
      }
    });

    toast({
      title: "Loading Graph..",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    // Update local states to ensure selection memory when reopening data tab
    setNodeConnectionsSelection(nodeConnections);

    setGraphNodeProperties({
      sourceNodePropsSelection: sourceNodeProps,
      nodeConnections: nodeConnections,
      fileDelimiter: modalFileDelimiter,
    });

    // Update global variables
    setGraphEvents({
      ...graphEvents,
      clickedNode: null,
    });

    setGraphEdgeProperties({
      edgeLabel: edgeLabelInput,
      edgeLabelType: edgeLabelTypeSelection,
      edgeWeight: validatedEdgeWeights,
      graphDirection: direction,
    });

    setGraphColorProperties({
      nodeColors: nodeColorPropertySelection,
      edgeColors: edgeColorPropertySelection,
    });

    // Capture all data
    setSelectedData(data);

    // Finally, reduce & filter the data to only include sourceNode, targetNodes, and edgeLabels which will be sent to create the graph
    const filteredData = data.data.reduce(
      (acc, cur) => {
        const filteredRow = {};
        Object.keys(cur).forEach((key) => {
          if (
            sourceNodes.includes(key) ||
            targetNodes.includes(key) ||
            edgeLabelInput.includes(key)
          ) {
            filteredRow[key] = cur[key];
          }
        });
        const key = Object.values(filteredRow).join("|");
        if (!acc.seen[key]) {
          acc.seen[key] = true;
          acc.data.push(filteredRow);
        }
        return acc;
      },
      { seen: {}, data: [] }
    ).data;

    const meta = {
      ...data.meta,
      fields: data.meta.fields.filter(
        (field) => sourceNodes.includes(field) || targetNodes.includes(field)
      ),
    };

    const metaByProperty = {
      ...data.meta,
      fields: data.meta.fields.filter(
        (field) =>
          sourceNodes.includes(field) ||
          targetNodes.includes(field) ||
          sourceNodeProps.includes(field)
      ),
    };

    // Filter the data and retain only the desired columns
    const filteredDataByProperty = data.data.map((row) => {
      let columnsToRetain = new Set(
        sourceNodes.concat(targetNodes).concat(sourceNodeProps)
      );
      // Create a new object for the row with only the desired columns
      const filteredRow = {};
      Object.keys(row).forEach((column) => {
        if (columnsToRetain.has(column)) {
          filteredRow[column] = row[column];
        }
      });
      return filteredRow;
    });

    const newData = { ...data, data: filteredData, meta };
    const dataToExport = {
      ...data,
      data: filteredDataByProperty,
      metaByProperty,
    };
    setFilteredExportData(dataToExport);
    setFilteredSelectedData(newData);
  };

  // Saves user's uploaded file
  function onInputChange(file) {
    setFile(file);
    setFileName(file.name);
  }

  // Handles user changing source node on data summary modal
  function handleSourceNodeChange(
    node,
    sourceNode,
    nodeConnections,
    setNodeConnections,
    targetNodes,
    setNumEdges,
    data,
    graphColorPropertySelection,
    setGraphColorPropertySelection
  ) {
    // Initialize an array to store the order of keys
    const nodeConnectionKeys = Object.keys(nodeConnections);

    // Error handling for user choosing two identical source nodes
    if (nodeConnectionKeys.includes(node)) {
      alert(
        "You have already chosen that parameter as a source node, please choose another."
      );
      return;
    }

    const updatedNodeConnections = { ...nodeConnections };
    const newNodeConnections = {};

    // Update nodeConnections
    if (sourceNode !== node) {
      if (targetNodes.includes(node)) {
        const currTargetNodes = [...targetNodes];
        currTargetNodes.unshift(sourceNode);
        const newTargetNodeIndex = currTargetNodes.indexOf(node);

        if (newTargetNodeIndex > -1) {
          currTargetNodes.splice(newTargetNodeIndex, 1);
          calcEdgeSize(currTargetNodes, data, setNumEdges);
          updatedNodeConnections[node] = currTargetNodes;
        }
      } else {
        updatedNodeConnections[node] = updatedNodeConnections[sourceNode];
      }

      // Update the order of the keys
      const sourceNodeIndex = nodeConnectionKeys.indexOf(sourceNode);
      if (sourceNodeIndex > -1) {
        nodeConnectionKeys[sourceNodeIndex] = node;
      }

      // create new object with correctly ordered keys
      nodeConnectionKeys.forEach((key) => {
        newNodeConnections[key] = updatedNodeConnections[key];
      });

      setNodeConnections(newNodeConnections);
    }

    // Update node color selections
    const updatedGraphColorSelection = {
      ...graphColorPropertySelection.nodeColorSelection,
    };
    const sourceNodeColor = updatedGraphColorSelection[sourceNode];

    if (![].concat(...Object.values(newNodeConnections)).includes(sourceNode)) {
      // updated new node color to existing sourceNode color
      updatedGraphColorSelection[node] = sourceNodeColor;
      delete updatedGraphColorSelection[sourceNode];
    } else {
      updatedGraphColorSelection[node] = sourceNodeColor;
    }
    setGraphColorPropertySelection({
      ...graphColorPropertySelection,
      nodeColorSelection: updatedGraphColorSelection,
    });
  }

  // Handles user changing target node on data summary modal
  function handleTargetNodeChange(
    node,
    targetNode,
    targetNodes,
    sourceNode,
    nodeConnections,
    setNodeConnections,
    setNumEdges,
    index,
    data,
    graphColorPropertySelection,
    setGraphColorPropertySelection
  ) {
    if (targetNodes.includes(node)) {
      alert("Cannot select two identical columns");
    } else {
      setNodeConnections((prevNodeConnections) => {
        const updatedConnections = { ...prevNodeConnections };
        const currentTargetConnections = [...prevNodeConnections[sourceNode]];
        currentTargetConnections.splice(index, 1, node);
        updatedConnections[sourceNode] = currentTargetConnections;
        return updatedConnections;
      });

      // re-calc the edge size(s)
      calcEdgeSize(
        [...targetNodes.slice(0, index), node, ...targetNodes.slice(index + 1)],
        data,
        setNumEdges
      );
    }

    // Update node color selections
    const updatedGraphColorSelection = {
      ...graphColorPropertySelection.nodeColorSelection,
    };
    const targetNodeColor = updatedGraphColorSelection[targetNode];

    // Collect all targetNodes except for current targetNode
    const allTargetNodes = [].concat(...Object.values(nodeConnections));
    let foundFirstInstance = false;

    const filteredTargetNodes = allTargetNodes.filter((value) => {
      if (foundFirstInstance && value === targetNode) {
        // If we've already found the first instance, keep all values
        return true;
      } else if (value === targetNode && !foundFirstInstance) {
        // If this is the first string instance, mark it as found
        foundFirstInstance = true;
        return false;
      } else {
        return true;
      }
    });

    if (
      !filteredTargetNodes.includes(targetNode) &&
      !Object.keys(nodeConnections).includes(targetNode)
    ) {
      // updated new node color to existing targetNode color
      updatedGraphColorSelection[node] = targetNodeColor;
      delete updatedGraphColorSelection[targetNode];
    } else {
      updatedGraphColorSelection[node] = targetNodeColor;
    }
    setGraphColorPropertySelection({
      ...graphColorPropertySelection,
      nodeColorSelection: updatedGraphColorSelection,
    });
  }

  // Multi step modal: step 1 uploads data, step 2 gives summary and set graph parameters
  return (
    <>
      {step === 1 ? (
        <DataImportModal
          isOpen={isOpen}
          onClose={onClose}
          hiddenInputExcel={hiddenInputExcel}
          hiddenInputCsv={hiddenInputCsv}
          fileName={fileName}
          file={file}
          handleDataUpload={handleDataUpload}
          onInputChange={onInputChange}
        />
      ) : (
        <DataSummaryModal
          data={data}
          file={file}
          fileDelimiter={graphNodeProperties.fileDelimiter}
          isOpen={isOpen}
          onClose={onClose}
          step={step}
          setStep={setStep}
          nodeConnectionsSelection={nodeConnectionsSelection}
          sourceNodePropsSelection={
            graphNodeProperties.sourceNodePropsSelection
          }
          graphEdgeProperties={graphEdgeProperties}
          initialNumEdges={initialNumEdges}
          graphColorProperties={graphColorProperties}
          calcEdgeSize={calcEdgeSize}
          handleTargetNodeChange={handleTargetNodeChange}
          handleSourceNodeChange={handleSourceNodeChange}
          handleSubmitData={handleSubmitData}
          SELECT_FONTSIZE={SELECT_FONTSIZE}
          NODEWIDTH={NODEWIDTH}
          COLORWIDTH={COLORWIDTH}
          PROPWIDTH={PROPWIDTH}
          STATISTIC_FONTSIZE={STATISTIC_FONTSIZE}
          resetGraph={resetGraph}
        />
      )}
    </>
  );
}

export default MultiStepDataImportModal;
