// Sigma Imports
import {
  SigmaContainer,
  ControlsContainer,
  ZoomControl,
} from "@react-sigma/core";
import { SearchControls } from "./SearchControls";
import {
  FaExpand,
  FaPlus,
  FaMinus,
  FaRegPlayCircle,
  FaStop,
} from "react-icons/fa";
import { LayoutForceAtlas2Control } from "@react-sigma/layout-forceatlas2";
// Import the style file here - BEFORE you import the SigmaControls.css!
import "@react-sigma/core/lib/react-sigma.min.css";
import "./SigmaControls.css";

// Load custom components
// import NodeInfoDrawer from "../NodeInfoDrawer";
import NodeInfoDrawer from "../NodeInfoDrawer";
import CreateGraph from "./CreateGraph";
import { Legend } from "./Legend";

// Load Chakra Components
import { Box, Flex, Stack } from "@chakra-ui/react";

/**
 * Creates the sigma.js graph instance and returns the rendered graph instance.
 *
 * @param {object} selectedData - The selected data for the graph.
 * @param {object} filteredSelectedData - The filtered selected data used for rendering the graph.
 * @param {object} graphNodeProperties - Object representing the properties of graph nodes.
 * @param {array} graphColorProperties - Object containing the colors for the graph.
 * @param {object} graphEdgeProperties - Object representing the properties of graph edges.
 * @param {array} graphEvents - Object containing the events for the graph.
 * @param {function} setGraphEvents - Callback function to set the graph events.
 * @param {object} graphSettings - Object containing the settings of the graph.
 * @param {function} setGraphSettings - Callback function to set the graph settings.
 * @param {function} setIsLoading - Callback function to set the loading state of the graph.
 * @param {boolean} isAnalysisDrawerOpen - Specifies whether the analysis drawer is open or closed.
 * @param {function} onAnalysisDrawerClose - Callback function to handle the closing of the analysis drawer.
 * @param {function} onAnalysisDrawerOpen - Callback function to handle the opening of the analysis drawer.
 * @param {number} LEFT_CONTROL_TAB_WIDTH - The width of the left control tab.
 * @param {number} userDefinedNodeSizeMin - The smallest possible node size.
 * @param {number} userDefinedNodeSizeMax - The largest possible node size.
 */

export default function GraphContainer({
  selectedData,
  filteredSelectedData,
  graphNodeProperties,
  graphColorProperties,
  graphEdgeProperties,
  graphEvents,
  setGraphEvents,
  graphSettings,
  setGraphSettings,
  setIsLoading,
  isAnalysisDrawerOpen,
  onAnalysisDrawerClose,
  onAnalysisDrawerOpen,
  LEFT_CONTROL_TAB_WIDTH,
  userDefinedNodeSizeMin,
  userDefinedNodeSizeMax,
}) {
  return (
    <Flex h="100%">
      <Stack h="100%" m={0} p={0} direction="row" spacing={0}>
        <Box
          h="100%"
          justifyContent="center"
          w={
            isAnalysisDrawerOpen && graphEvents.clickedNode
              ? `calc(80vw - ${LEFT_CONTROL_TAB_WIDTH})`
              : `calc(100vw - ${LEFT_CONTROL_TAB_WIDTH})`
          }
        >
          <SigmaContainer
            renderer="webgl"
            settings={{
              drawLabels: true, // Set to true to display labels
              labelThreshold: 0, // Adjust as needed
            }}
          >
            <CreateGraph
              filteredSelectedData={filteredSelectedData}
              graphColorProperties={graphColorProperties}
              graphNodeProperties={graphNodeProperties}
              graphEdgeProperties={graphEdgeProperties}
              graphEvents={graphEvents}
              setGraphEvents={setGraphEvents}
              isOpen={isAnalysisDrawerOpen}
              onOpen={onAnalysisDrawerOpen}
              onClose={onAnalysisDrawerClose}
              graphSettings={graphSettings}
              setGraphSettings={setGraphSettings}
              setIsLoading={setIsLoading}
              userDefinedNodeSizeMin={userDefinedNodeSizeMin}
              userDefinedNodeSizeMax={userDefinedNodeSizeMax}
            />
            {!graphSettings.colorByCommunity ? (
              <Legend
                graphColorProperties={graphColorProperties}
                graphSettings={graphSettings}
              />
            ) : null}
            <ControlsContainer position={"bottom-right"}>
              <ZoomControl>
                <FaPlus fontSize="1.1rem" />
                <FaMinus fontSize="1.1rem" />
                <FaExpand fontSize="1.2rem" />
              </ZoomControl>
              <LayoutForceAtlas2Control
                settings={{
                  settings: {
                    slowDown: 15,
                    gravity: 0.01,
                    barnesHutOptimize: true,
                    scalingRatio: 150,
                  },
                }}
              >
                <FaRegPlayCircle fontSize="1.4rem" />
                <FaStop fontSize="1.4rem" />
              </LayoutForceAtlas2Control>
            </ControlsContainer>
            <ControlsContainer position={"top-left  "}>
              <SearchControls
                style={{ width: "200px" }}
                setGraphEvents={setGraphEvents}
                graphEvents={graphEvents}
              />
            </ControlsContainer>
          </SigmaContainer>
        </Box>
        <NodeInfoDrawer
          selectedData={selectedData}
          graphNodeProperties={graphNodeProperties}
          graphSettings={graphSettings}
          graphEvents={graphEvents}
          setGraphEvents={setGraphEvents}
          isOpen={isAnalysisDrawerOpen}
          onClose={onAnalysisDrawerClose}
        />
      </Stack>
    </Flex>
  );
}
