import React from "react";
import "./resume.css";
import bp from "../images/bp_logo.png";
import soldyn from "../images/soldyn_logo.png";
import ou from "../images/OU_logo.png";
import occc from "../images/OCCC_logo.png";
import prof_pic from "../images/Prof_pic.jpg";

export default function Resume() {
  return (
    <>
      <div className="resume-container">
        <div className="resume-top-container">
          <div className="resume-name">
            <h2>Andy</h2>
            <h2>Czeropski</h2>
          </div>
          <div className="resume-prof-pic">
            <img src={prof_pic} alt="Andy Czeropski" id="prof-img" />
          </div>
          <div className="resume-careers-container">
            <div className="resume-careers-wrapper">
              <h3 id="data-science">Data Scientist</h3>
              <h3 id="engineer">Engineer</h3>
              <h3 id="software-development">Software Developer</h3>
            </div>
          </div>
        </div>
        <hr className="resume-hr" />
        <div className="resume-center-container">
          <div className="resume-skills-container">
            <h2 id="skills">Skills.</h2>
            <div className="resume-skills-wrapper">
              <h3 id="data-science">Data Science</h3>
              <div className="resume-data-science">
                <div className="skill-col">
                  <p>Python</p>
                  <p>scikit-learn</p>
                  <p>FastAI</p>
                </div>
                <div className="skill-col">
                  <p>TensorFlow</p>
                  <p>Azure</p>
                  <p>Keras</p>
                </div>
                <div className="skill-col">
                  <p>Pandas/Numpy</p>
                  <p>SQL</p>
                  <p>PyTorch</p>
                </div>
              </div>
              <h3 id="engineer">Engineering</h3>
              <div className="resume-engineer">
                <div className="skill-col">
                  <p>SolidWorks</p>
                  <p>VBA</p>
                  <p>FEA</p>
                </div>
                <div className="skill-col">
                  <p>Catia</p>
                  <p>PowerBI</p>
                  <p>Stimplan</p>
                </div>
                <div className="skill-col">
                  <p>Ansys</p>
                  <p>C++</p>
                  <p>MatLab</p>
                </div>
              </div>
              <h3 id="software-development">Software Development</h3>
              <div className="resume-software-developer">
                <div className="skill-col">
                  <p>Python </p>
                  <p>Node.js</p>
                  <p>Docker</p>
                </div>
                <div className="skill-col">
                  <p>React</p>
                  <p>CSS</p>
                  <p>SQL</p>
                </div>
                <div className="skill-col">
                  <p>Javascript</p>
                  <p>HTML</p>
                  <p>Git</p>
                </div>
              </div>
            </div>
          </div>
          <div className="resume-interests-container">
            <h2 id="education">Education.</h2>
            <div className="resume-education-wrapper">
              <div className="resume-education">
                <div className="resume-ou">
                  <div className="resume-school-logo">
                    <img src={ou} alt="University of Oklahoma" />
                  </div>
                  <div className="resume-school-text">
                    <p>University of Oklahoma </p>
                    <p>B.S. Mechanical Engineering (4.0/4.0)</p>
                    <p>2016-2018 - Norman, Oklahoma</p>
                  </div>
                </div>
                <div className="resume-occc">
                  <div className="resume-school-logo">
                    <img src={occc} alt="Oklahoma City Community College" />
                  </div>
                  <div className="resume-school-text">
                    <p>Oklahoma City Community College </p>
                    <p>A.S. Math/Engineering (4.0/4.0)</p>
                    <p>2014-2016 - Oklahoma City, Oklahoma</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="resume-passions-container">
              <h2 id="passions">Passions.</h2>
              <div className="resume-passions-wrapper">
                <div className="resume-passions">
                  <div className="resume-passions-top">
                    <div>
                      <i class="fas fa-laptop-code"></i>
                      <p>Coding</p>
                    </div>
                    <div>
                      <i class="fas fa-piggy-bank"></i>
                      <p>Finance</p>
                    </div>
                    <div>
                      <i class="fas fa-home"></i>
                      <p>Real Estate</p>
                    </div>
                    <div>
                      <i class="fas fa-car"></i>
                      <p>Cars</p>
                    </div>
                  </div>
                  <div className="resume-passions-bottom">
                    <div>
                      <i class="fas fa-dumbbell"></i>
                      <p>Lifting</p>
                    </div>
                    <div>
                      <i class="fas fa-campground"></i>
                      <p>Camping</p>
                    </div>
                    <div>
                      <i class="fas fa-fish text-center"></i>
                      <p>Fishing</p>
                    </div>
                    <div>
                      <i class="fas fa-gamepad"></i>
                      <p>Gaming</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="resume-bottom-container">
          <div className="resume-exp-container">
            <h2 id="experience">Experience.</h2>
            <div className="resume-exp-wrapper">
              <div className="resume-bp-container">
                <div id="bp-logo">
                  <img src={bp} alt="bp" />
                </div>
                <div className="resume-bp-roles">
                  <div>
                    <h4>Data Scientist, Advanced Analytics — bp </h4>
                    <h5>Sep. 2020 - Present</h5>
                    <ul>
                      <li>
                      Utilized python to implement reinforcement learning modeling that
                       optimized hydraulic well completion parameters in real time resulting
                        in $400k of annual rig time savings, and $22MM in increased production annually
                      </li>
                      <li>
                      Productionized reinforcement learning models by creating a feature rich GUI
                       utilizing an API data receiver and python physics simulator that outputted
                        results to a PowerBI dashboard enabling engineers to make critical real time decisions
                      </li>
                      <li>
                      Deployed RCNN object detection model to Boston Dynamics Robot dog ‘Spot’ allowing
                       autonomous remote inspections of offshore platforms while performing tasks like
                        scanning for abnormalities, tracking corrosion, and checking gauges
                      </li>
                      <li>
                      Built an aviation fuel decal image classification model using TensorFlow deployed
                       to Android/iOS phones that has enabled more than 300,000 successful aircraft fuel
                        stops without incident
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4>GOM Subsea Project Engineer — bp</h4>
                    <h5>Oct. 2019 - Sep. 2020</h5>
                    <ul>
                      <li>
                      Supervised agile team of contractors and bp engineers to refurbish
                       and deliver the first subsea tree system under the new “test, reuse,
                        redeploy” strategy while beating delivery schedule by 3 weeks
                      </li>
                      <li>
                      Analyzed global subsea tree systems data and spearheaded
                       initiative to "test, reuse, and redeploy" subsea tree
                        systems when possible, resulting in savings of $4MM
                         in 2020 and $12MM in 2021 whilst reducing overall carbon footprint
                      </li>
                      <li>
                        Responsible for delivering refurbished subsea equipment
                        including trees, tubing hangers, chokes, tree caps,
                        crown plugs, etc.
                      </li>
                      <li>
                        Coordinated mobilization/demobilization of refurbished
                        subsea equipment to and from offshore
                      </li>
                      <li>
                        Managed suppliers and contractors to ensure they are
                        meeting BP specifications related to safety, quality,
                        cost, and delivery of equipment.
                      </li>
                      <li>
                        Oversee factory acceptance testing, pre-deployment
                        testing, and final factory inspections for refurbished
                        subsea trees.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4>Discipline Tree Engineer — bp </h4>
                    <h5>Jun. 2018 - Oct. 2019</h5>
                    <ul>
                      <li>
                        Led risk assessment activities to ensure new subsea tree
                        designs were safe and fit for service.
                      </li>
                      <li>
                        Established self-verification activities for subsea tree
                        designs and ensured proper execution.
                      </li>
                      <li>
                        Verified that new tree designs met industry standards,
                        regulatory requirements, and appropriate BP standards.
                      </li>
                      <li>
                      Implemented the first subsea tree systems database to capture
                       bp tribal knowledge, historic subsea tree system schematics,
                        pricing, and quick reference guides that gave engineers access
                         to tree system data insights for the first time
                      </li>
                      <li>
                      Drove increased product value through cost savings (~15%) and cycle
                       time reductions (~20%) by supporting the industry led initiative to
                        standardize subsea hardware components
                      </li>
                      <li>
                        Incorporated simplification, efficiency, continuous
                        improvement, and lessons learned into tree designs.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="resume-soldyn-container">
                <div id="soldyn-logo">
                  <img src={soldyn} alt="solution dynamics" />
                </div>
                <div className="resume-soldyn-roles">
                  <div>
                    <h4>Mechanical Engineer - Solution Dynamics</h4>
                    <h5>May 2017 - Jan. 2018</h5>
                    <ul>
                      <li>
                        Created proprietary excel templates to estimate
                        cost/energy savings when installing variable frequency
                        drives on:
                        <ul>
                          <li>Single + Parallel Pumping Systems</li>
                          <li>Single + Parallel Fan Systems</li>
                          <li>Single + Parallel Cooling Tower Systems</li>
                        </ul>
                      </li>
                      <li>
                        Excel calculations resulted in a 17% energy usage
                        reduction across all 3 systems by intelligent sequencing
                        of equipment.
                      </li>
                      <li>
                        Performed a preliminary energy analysis at two meat
                        processing facilities to identify cost saving opportunities
                        while collaborating with a team of engineers. Three
                        major opportunities found resulting in $360k worth of
                        savings.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
