import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  IconButton,
  Slide,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FaMinus } from "react-icons/fa";

/**
 * Create an imitation drawer that slides out when clicking a node
 *
 * @param {object} - The object containing the function parameters.
 * @param {object} selectedData - The selected data for the node.
 * @param {object} graphNodeProperties - Object representing the properties of the graph nodes.
 * @param {object} graphSettings - Object representing the general settings of the graph.
 * @param {array} graphEvents - Object containing the events for the graph.
 * @param {function} setGraphEvents - Callback function to set the graph events.
 * @param {boolean} isOpen - Specifies whether the Node Info Drawer is open or closed.
 * @param {function} onClose - Callback function to handle the closing of the Node Info Drawer.
 */

export default function NodeInfoDrawer({
  selectedData,
  graphNodeProperties,
  graphSettings,
  graphEvents,
  setGraphEvents,
  isOpen,
  onClose,
}) {
  let nodeColor = "";
  let nodeType = "";
  let neighbors = [];
  let sourceNodePropDict = {};
  let nodeHeader = "";

  if (graphSettings.graphData && graphEvents.clickedNode) {
    nodeColor = graphSettings.graphData.getNodeAttributes(
      graphEvents.clickedNode
    )["color"];
    nodeType = graphSettings.graphData.getNodeAttributes(
      graphEvents.clickedNode
    )["nodeType"];
    neighbors = graphSettings.graphData.neighbors(graphEvents.clickedNode);
    nodeHeader = graphSettings.graphData.getNodeAttributes(
      graphEvents.clickedNode
    )["header"];

    // Create empty dict of source & target node properties each containing a Set to remove dupes
    sourceNodePropDict = Object.fromEntries(
      graphNodeProperties.sourceNodePropsSelection.map((x) => [x, new Set()])
    );

    selectedData.data.map((line) => {
      let value = line[nodeHeader];

      // values should be in string format
      if (value && typeof value !== "string") {
        // convert to string so we can check for delimiters
        value = value.toString();
      }

      // Check if the value contains the delimiter
      if (value && value.includes(graphNodeProperties.fileDelimiter)) {
        // Split the value into an array using the delimiter
        const valueArray = value
          .split(graphNodeProperties.fileDelimiter)
          .map((item) => item.trim());

        // Check if the clickedNode is in the array
        if (valueArray.includes(graphEvents.clickedNode)) {
          graphNodeProperties.sourceNodePropsSelection.forEach((prop) => {
            sourceNodePropDict[prop].add(line[prop]);
          });
        }
      } else if (value === graphEvents.clickedNode) {
        // Handle the case where value is not an array
        graphNodeProperties.sourceNodePropsSelection.forEach((prop) => {
          sourceNodePropDict[prop].add(line[prop]);
        });
      }
      return null;
    });
  }

  // Clicking node within drawer
  function handleDrawerNodeClick(node) {
    setGraphEvents({
      hoveredNode: node,
      clickedNode: node,
    });
    if (node) {
      // Move camera to clicked node only when user clicks inside drawer
      const x = graphSettings.sigmaInstance["nodeDataCache"][node].x;
      const y = graphSettings.sigmaInstance["nodeDataCache"][node].y;
      graphSettings.sigmaInstance
        .getCamera()
        .animate({ x, y }, { easing: "linear", duration: 500 });
    }
  }

  // Custom button that highlights connected node on click
  function ConnectedNodeButton({ node }) {
    let neighborNodeColor = node
      ? graphSettings.graphData.getNodeAttributes(node)["color"]
      : "";
    // let neighborNodeType = node ? graphSettings.graphData.getNodeAttributes(node)["nodeType"] : "";
    let neighborNodeHeader = node
      ? graphSettings.graphData.getNodeAttributes(node)["header"]
      : [];

    return (
      <Button
        py={6}
        justifyContent="left"
        w="100%"
        bg="white"
        onClick={() => handleDrawerNodeClick(node)}
        _hover={{ mx: 2, bg: "gray.100", borderRadius: 12 }}
      >
        <Stack alignItems="center" direction="row">
          <Text
            pt="0.3rem"
            w="2.2rem"
            h="2.2rem"
            fontSize="1.25rem"
            bg={neighborNodeColor}
            color="white"
            borderRadius="25%"
          >
            {neighborNodeHeader.slice(0, 1)}
          </Text>
          <Stack textAlign="left" pl={2} spacing={0.5}>
            <Text color="gray.700">{node}</Text>
            <Text fontSize="0.8rem" color={neighborNodeColor}>
              {neighborNodeHeader}
            </Text>
          </Stack>
        </Stack>
      </Button>
    );
  }

  return (
    // Creating an imitation drawer below
    <>
      {graphEvents.clickedNode && (
        <Slide
          direction="right"
          in={isOpen}
          style={{
            marginTop: "auto",
            height: "calc(100vh - 5rem)",
            width: "20vw",
            zIndex: 100,
          }}
        >
          <Box h="100%" borderLeft="2px" borderColor="gray.200">
            <VStack color="black" bg="white" spacing={0} h="100%" w="100%">
              <Box
                w="100%"
                display="flex"
                bg={nodeColor}
                h="6rem"
                justifyContent="end"
              >
                <IconButton
                  bg={nodeColor}
                  aria-label="Close Control Panel"
                  icon={<CloseIcon />}
                  onClick={() => {
                    onClose();
                    setGraphEvents({
                      hoveredNode: null,
                      clickedNode: null,
                    });
                  }}
                  color="gray.100"
                  _hover={{ bg: "none", color: "gray.400" }}
                />
              </Box>
              <Box pb={4} w="100%" h="5rem" bg={nodeColor} color="white">
                <Stack pl={4} textAlign="left" spacing={0}>
                  <Heading size="md">{graphEvents.clickedNode}</Heading>
                  <Text pb={4} color="gray.100" fontSize={16}>
                    {" "}
                    {nodeHeader}
                  </Text>
                </Stack>
              </Box>
              <Box
                borderWidth={1}
                borderColor="gray.300"
                w="100%"
                bg="gray.200"
              >
                <Heading textAlign="center" py={2} size="sm">
                  PROPERTIES
                </Heading>
              </Box>

              {nodeType === "source" &&
              graphNodeProperties.sourceNodePropsSelection.length > 0 ? (
                <Box
                  w="100%"
                  pt={2}
                  pb={4}
                  px={4}
                  minH="15vh"
                  maxH="35vh"
                  overflowY="scroll"
                >
                  <Stack>
                    {graphNodeProperties.sourceNodePropsSelection.map(
                      (prop, index) => (
                        <Stack key={"stack-" + prop + "-" + index} spacing={0}>
                          <Text
                            key={"prop-header" + prop + "-" + index}
                            fontWeight="semibold"
                            fontSize="0.95rem"
                            textAlign="left"
                          >
                            {prop}:
                          </Text>
                          <Text key={prop + "-" + index} fontSize="0.9rem">
                            {[...sourceNodePropDict[prop]]
                              .join(", ")
                              .replace(/,\s*$/, "")
                              .trim()}
                          </Text>
                        </Stack>
                      )
                    )}
                  </Stack>
                </Box>
              ) : (
                <Box w="100%" pt={2} pb={4} px={4} h="5vh" overflowY="scroll">
                  <Text
                    fontWeight="semibold"
                    fontSize="0.95rem"
                    textAlign="left"
                  >
                    {nodeType === "source"
                      ? "No properties selected to display."
                      : "Target nodes do not have properties."}
                  </Text>
                </Box>
              )}
              <Box
                borderWidth={1}
                borderColor="gray.300"
                w="100%"
                bg="gray.200"
              >
                <Heading textAlign="center" py={2} size="sm">
                  CONNECTIONS
                </Heading>
              </Box>
              <Box pt={4} w="100%">
                <Stack>
                  <Stack direction="row" alignItems="center" pl={4}>
                    <Text
                      borderRadius={10}
                      px={2}
                      color="white"
                      fontWeight="bold"
                      bg={nodeColor}
                    >
                      {neighbors.length}
                    </Text>
                    <FaMinus color={nodeColor} />
                    <Text color={nodeColor}> Connected Nodes </Text>
                  </Stack>
                  <Center>
                    <Divider borderColor="gray.400" w="93%" m="auto" />
                  </Center>
                </Stack>
              </Box>
              <Box
                pt={2}
                h="90%"
                overflowY="scroll"
                overflowX="hidden"
                w="100%"
              >
                <Stack spacing={0}>
                  {neighbors.map((node) => (
                    <ConnectedNodeButton key={node} node={node} />
                  ))}
                </Stack>
              </Box>
            </VStack>
          </Box>
        </Slide>
      )}
    </>
  );
}
